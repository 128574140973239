import axiosClient from "config/apiClient";
import {
  clientPayload,
  // putClientpayload
} from "../modules/types/client.model";
import { fetchData } from "helper/fetchData";
let header = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
export const client = {
    getclient: () => axiosClient.get("tenant/get/tenants/"),
    createClient: (clientPayload:clientPayload) =>axiosClient.post("tenant/manage/",clientPayload,),
    getByidclient: (queryParams) => axiosClient.get(`tenant/get/tenants/?id=${queryParams}`),
    putbyidClient: (queryParams,putClientpayload) =>axiosClient.put(`/tenant/manage/${queryParams}/`, putClientpayload,header),
    fetchTenant : (id) => fetchData(`tenant/get/tenants/?id=${id}`)
};


