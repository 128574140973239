import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  VStack,
  Text,
  Input,
  Button,
  InputGroup,
  Grid,
  GridItem,
  Image,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import Logo from "../../../assets/game1/Logo.png";
import { useNavigate, useParams } from "react-router";
import { useCommonToast } from "components/toast/toast";
import { useGameStore } from "store/store";
import { handleGetConfig, handleWorkshopLogin } from "modules/auth";
import { WorkshopData } from "store/workshop/workShopSlice";
import { loginfailResponse } from "modules/types/auth.model";
import { PublicClientApplication} from "@azure/msal-browser";
import {
  tenantConfigSuccessResponse,
} from "modules/types/auth.model";
import { MicrosoftSignIn } from "views/auth/microsoftSignIn";

const LoginComponent = () => {
  const [teamName, setTeamName] = useState("");
  const [gameCode, setGameCode] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const { acronym } = useParams();
  const { clientName } = useParams();
  const [initialLoading, setInitialLoading] = useState(false);
  const showToast = useCommonToast();
  const {
    setWorkshop,
    Game2reset,
    Game3reset,
    Game1reset,
    setPermissionsList,
    setauthData,
  } = useGameStore();
  const navigate = useNavigate();

  const [configurationData, setConfigurationData] =
  useState<tenantConfigSuccessResponse | null>(null);
const [msalInstance, setMsalInstance] =
  useState<PublicClientApplication | null>(null);

  const handleTeamChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTeamName(e.target.value);
    setErrors((prev) => ({ ...prev, teamName: "" }));
  };

  const handleGameCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGameCode(e.target.value);
    setErrors((prev) => ({ ...prev, gameCode: "" }));
  };

  const validateForm = (): boolean => {
    const newErrors: { [key: string]: string } = {};
    if (!teamName.trim()) {
      newErrors.teamName = "Team name is required";
    }
    if (!gameCode.trim()) {
      newErrors.gameCode = "Game code is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function clearData() {
    Game1reset();
    Game2reset();
    Game3reset();
    setWorkshop(null);
    setPermissionsList(null);
    setauthData(null);
  }

  const resetform = () => {
    setTeamName("");
    setGameCode("");
  };

  const workshopEntry = async () => {
    let payload = {
      team_name: teamName,
    }
    handleWorkshopLogin(payload,gameCode).then((resp : WorkshopData | loginfailResponse) =>{
      const workshopData = resp as WorkshopData;
      workshopData["acronym"] = gameCode;
      setWorkshop(workshopData);
      showToast("Success", "Welcome to the Workshop", "success");
      resetform();
      navigate("/play/about");
    }).catch((error:any)=>{
      showToast(
        "Error",
        error.response?.data?.detail || error.response?.data?.error,
        "error"
      );
    }).finally(()=>{
      setIsLoading(false);
    })
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      await workshopEntry();
    } else {
      if(!clientName){
        showToast("Error", "Please fill in all required fields.", "error");
      }
    }
  };

  const initializeConfiguration = async () => {
    try {
      setInitialLoading(true);
      setauthData(null);
      setPermissionsList(null);
      Game1reset();
      Game2reset();
      Game3reset();

      if (clientName) {
        const response = await handleGetConfig(clientName);
        const data = response as tenantConfigSuccessResponse;
        setConfigurationData(data);

        const msalConfig = {
          auth: {
            clientId: data.client_id,
            authority: `https://login.microsoftonline.com/${data.tenant_id}/v2.0`,
            redirectUri: window.location.origin + "/oauth",
          },
          cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false,
            cacheCrossTabSync: true,
          },
        };

        const instance = new PublicClientApplication(msalConfig);
        setMsalInstance(instance);
      }
    } catch (error) {
      navigate("/auth/login")
      console.error("Configuration initialization failed", error);
    } finally {
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    initializeConfiguration();
  }, []);

  useEffect(() => {
    clearData();
    if (acronym) {
      setGameCode(acronym);
    }
  }, []);

  return (
    <Box
      id="addclient-form"
      overflowX="auto"
      borderRadius={"20px"}
      display="flex"
      flexDirection="column"
      flex={1}
      fontFamily="Poppins"
      mx={{ base: "0vw", md: "10vw" }}
      my={{ base: "0vh", md: "5vh" }}
    >
      {initialLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <div className="border-white-600 mr-4 h-10 w-10 animate-spin rounded-full border-t-2"></div>
        </div>
      ) : (
        <Flex direction={"column"} flex={1}>
          <Flex
            bg={"Containerbg"}
            flex={1}
            py={"10px"}
            px={"20px"}
            direction={"column"}
            justifyContent="space-between"
          >
            <Box as={Flex} flex={1}>
              <Flex
                justifyContent={"center"}
                alignItems={"center"}
                w={"100%"}
                flex={1}
              >
                <Grid
                  as={Flex}
                  templateColumns="repeat(2, 1fr)"
                  gap={6}
                  w={"100%"}
                  flex={1}
                >
                  <GridItem
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                    bg={"white"}
                    borderRadius={"8px"}
                    p={8}
                    mx={2}
                  >
                    <Box bg={"white"} display="flex" flex={1}>
                      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <VStack spacing={4} align="stretch" w="100%">
                          <Text
                            textAlign="center"
                            color={"#0E389D"}
                            fontWeight={700}
                            fontSize={18}
                          >
                            {clientName ? "Welcome Back!" : "Bar Game"}
                          </Text>
                          <Text textAlign="center">{clientName ? "Sign in to continue to ROAI App." : "Login to continue"}</Text>
                          <FormControl isInvalid={!!errors.teamName}>
                            <FormLabel>Team Name</FormLabel>
                            <Input
                              type="text"
                              value={teamName}
                              onChange={handleTeamChange}
                              placeholder={`Enter your team name ${clientName? "(Optional)": ""}`}
                            />
                            <FormErrorMessage>
                              {errors.teamName}
                            </FormErrorMessage>
                          </FormControl>
                          {configurationData &&
                          msalInstance &&
                          configurationData.provider_type === "azure" ? (
                            <Box>
                              <MicrosoftSignIn
                                msalInstance={msalInstance}
                                configurationData={configurationData}
                                teamName={teamName}
                              />
                            </Box>
                          ) : (
                            <>
                              <FormControl isInvalid={!!errors.gameCode}>
                                <FormLabel>Game Code</FormLabel>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    value={gameCode}
                                    onChange={handleGameCodeChange}
                                    placeholder="Enter your game code"
                                  />
                                </InputGroup>
                                <FormErrorMessage>
                                  {errors.gameCode}
                                </FormErrorMessage>
                              </FormControl>
                              <Button
                                type="submit"
                                bgGradient="linear(to-b, #5C86DC,#2647B0)"
                                color={"white"}
                                isLoading={isLoading}
                              >
                                Login
                              </Button>
                            </>
                          )}
                        </VStack>
                      </form>
                    </Box>
                  </GridItem>
                  <GridItem
                    p={10}
                    as={Flex}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      display="flex"
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Image src={Logo} alt="Login visual" objectFit="cover" />
                    </Box>
                  </GridItem>
                </Grid>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default LoginComponent;

