

import { leaderboard } from "service/leaderboard";
import {game3} from "../service/game3"
import {getTimesuccessresponse,getTimefailResponse,getAllPeRecordssuccessresponse,getAllPeRecordsfailResponse,getgame3successresponse,getgame3failResponse,postGame3successresponse,postGame3failResponse,game3Payload} from "./types/game3.model"
import { GameInsights, HistoricalData, leaderboardfailResponse } from "./types/leaderboard.model";
import { LeaderboardData } from "views/game3/leaderboard/leaderboard";
import { getAllworkshopsuccessResponse } from "./types/workshops.model";

  export const getLeaderboardData = async (isPlayable,tenant,workshop,permission): Promise<LeaderboardData | leaderboardfailResponse> => {
    try {
      let response;
      if(isPlayable){
        response = await leaderboard.getleaderboardworkshop(tenant,workshop);
      }else{
        if(permission){
        }else{
          response = await leaderboard.getleaderboard();
        }
      }

      const data: LeaderboardData = response.data;

         if (response.status === 200) {
            return data;
            } 
    } catch (getInitialpatronsfailResponse) {
      throw getInitialpatronsfailResponse
    }
  };

  export const getAdminLeaderboardData = async (tenant,workshop): Promise<LeaderboardData | leaderboardfailResponse> => {
    try {
      let response;
      if(tenant && workshop){
        response = await leaderboard.getadminLeaderboardTenantWorkshop(tenant,workshop);
      }else {
        response = await leaderboard.getadminLeaderboardTenant(tenant);
        }

      const data: LeaderboardData = response.data;

         if (response.status === 200) {
            return data;
            } 
    } catch (getInitialpatronsfailResponse) {
      throw getInitialpatronsfailResponse
    }
  };

  export const postresetgamemetrics = async (payload): Promise<string|string> => {
    try {
      const response = await leaderboard.postReset(payload);
      const data: string = response.data;

      if (response.status === 200) {
        return data;
        }  
    } catch (postGame3failResponse) {
      throw postGame3failResponse
    }
  };

  export const getInsightData = async (isPlayable,tenantid=null,workshopid=null,multiselect,acronym=null): Promise<GameInsights | leaderboardfailResponse> => {
    try {
    let response;

    if (multiselect !== null) {
      if (!multiselect && tenantid) {
        response = await leaderboard.getInsightTenant(tenantid);
      } else {
        response = await leaderboard.getInsightTenantWorkshop(tenantid,workshopid);
      }
    } else {
      if (isPlayable) {
        response = await leaderboard.getInsightWorkshop(acronym,workshopid);
      } else {
        response = await leaderboard.getInsight();
      }
    }

    const data: GameInsights = response.data;

         if (response.status === 200) {
            return data;
            } 
    } catch (getInitialpatronsfailResponse) {
      throw getInitialpatronsfailResponse
    }
  };

  export const getWorkshopOptionsData = async (tenantid): Promise<getAllworkshopsuccessResponse[] | leaderboardfailResponse> => {
    try {
      const response = await leaderboard.getWorkshopOptions(tenantid)

      const data: getAllworkshopsuccessResponse[] = response.data;

         if (response.status === 200) {
            return data;
            } 
    } catch (getInitialpatronsfailResponse) {
      throw getInitialpatronsfailResponse
    }
  };

  export const getHistorical = async (payload): Promise<HistoricalData[]|leaderboardfailResponse> => {
    try {
      const response = await leaderboard.getHistorical(payload);
      const data: HistoricalData[] = response.data;

      if (response.status === 200) {
        return data;
        }  
    } catch (postGame3failResponse) {
      throw postGame3failResponse
    }
  };
  