

import { Game3Data, gameTimeData } from "store/game3/game3Slice";
import {game3} from "../service/game3"
import {getTimefailResponse,getAllPeRecordssuccessresponse,getAllPeRecordsfailResponse,getgame3failResponse,postGame3successresponse,postGame3failResponse,game3Payload, PatronDataStructure, getgame3} from "./types/game3.model"
import { authData } from "store/auth/authSlice";
import { WorkshopData } from "store/workshop/workShopSlice";
export const getTime = async (isPlayable:boolean,workshop?:WorkshopData): Promise<PatronDataStructure | getTimefailResponse> => {
    try {
       let response;
       if(isPlayable && workshop){
         response = (await game3.gettimeWorkshop(workshop)).data
       }else{
         response = (await game3.gettime()).data
       }
       const data: PatronDataStructure = response;
       return data;
    } catch (getTimefailResponse) {
      throw getTimefailResponse
    }
  };

  export const getGame3 = async (isPlayable:boolean,getgame3?:getgame3,workshop?:WorkshopData): Promise<Game3Data|getgame3failResponse> => {
    try {
      let response;
      if(isPlayable && workshop){
        response = (await game3.getgame3Workshop(workshop,getgame3)).data
      }else{
        response = (await game3.getgame3(getgame3)).data
      }
      const data: Game3Data = response;
      return data;
    } catch (getgame3failResponse) {
      throw getgame3failResponse
    }
  };

  export const getAllPeRecords = async (isPlayable:boolean,workshop?:WorkshopData): Promise<getAllPeRecordssuccessresponse|getAllPeRecordsfailResponse> => {
    try {
      let response;
      if(isPlayable && workshop){
        response = (await game3.getAllPeRecordsWorkshop(workshop)).data
      }else{
        response = (await game3.getAllPeRecords()).data
      }
      const data: getAllPeRecordssuccessresponse = response;
      return data;
    } catch (getAllPeRecordsfailResponse) {
      throw getAllPeRecordsfailResponse
    }
  };


    export const postgame3 = async (game3Payload:game3Payload): Promise<Game3Data|postGame3failResponse> => {
    try {
      const response = await game3.postgame3(game3Payload);
      const data: Game3Data = response.data;

      if (response.status === 200) {
        return data;
        }  
    } catch (postGame3failResponse) {
      throw postGame3failResponse
    }
  };

  export const postgame3Workshop = async (game3Payload,acronym=null): Promise<Game3Data|postGame3failResponse> => {
    try {
      const response = await game3.postgame3Workshop(game3Payload,acronym);
      const data: Game3Data = response.data;

      if (response.status === 200) {
        return data;
        }  
    } catch (postGame3failResponse) {
      throw postGame3failResponse
    }
  };