import { Slider, SliderTrack, SliderFilledTrack, SliderThumb,Tooltip, ChakraProvider, SliderMark, Box, ChakraBaseProvider} from "@chakra-ui/react"
import React from "react"

/* eslint-disable @typescript-eslint/no-unused-vars */
/* @ts-ignore */ 

function Progress({load,value}) {
    const [showTooltip, setShowTooltip] = React.useState(false)
    
    return (
    <ChakraBaseProvider>
    <Box px={3} ms={20} pt={"2px"} h={10} mb={2} backgroundColor={'white'}>
    <ChakraProvider>
      <Slider
        id='slider'
        value={value}
        min={0}
        max={200}
        colorScheme='yellow'
        onMouseEnter={() => setShowTooltip(true)}
        size={'lg'}
        isReadOnly
      >
        <SliderMark value={0} mt='2.5' ml='0' className="text-[10px]">
        0
        </SliderMark>
        <SliderMark value={20} mt='1' ml='0' fontSize='sm'>
        |
        </SliderMark>
        <SliderMark value={40} mt='1' ml='0' fontSize='sm'>
        |
        </SliderMark>
        <SliderMark value={60} mt='1' ml='0' fontSize='sm'>
        |
        </SliderMark>
        <SliderMark value={80} mt='1' ml='0' fontSize='sm'>
        |
        </SliderMark>
        <SliderMark value={100} mt='1' ml='0' fontSize='sm'>
        |
        </SliderMark>
        <SliderMark value={120} mt='1' ml='0' fontSize='sm'>
        |
        </SliderMark>
        <SliderMark value={140} mt='1' ml='0' fontSize='sm'>
        |
        </SliderMark>
        <SliderMark value={160} mt='1' ml='0' fontSize='sm'>
        |
        </SliderMark>
        <SliderMark value={180} mt='1' ml='0' fontSize='sm'>
        |
        </SliderMark>
        <SliderMark value={200} mt='2.5' ml='-2.5' className="text-[10px]">
        200
        </SliderMark>
        <SliderTrack height={'5'} borderRadius={'none'}>
          <SliderFilledTrack borderBottom={'solid'} borderBottomColor={'black'}/>
        </SliderTrack>
        <Tooltip
          hasArrow
          bg='blue.900'
          color='yellow.300'
          placement='top'
          fontSize={'lg'}
          closeOnClick={false}
          label={`${value>9?value:value===0?` `+value:`0`+value}`}
          arrowSize={15}
          isOpen={load}
          className="lg-max:hidden"
        >
          <SliderThumb h={0} w={0} ml={"7px"}>
          <Box color="black"/>
          </SliderThumb>
        </Tooltip>
      </Slider>
    </ChakraProvider>
    </Box>
    </ChakraBaseProvider>
    )
  }

export default Progress;