import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
  useToast,
} from "@chakra-ui/react";
import Select, { components } from "react-select";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import QRLinkModal from "components/workshopModal";
import Loader from "components/loader/loader";
import { useCommonToast } from "components/toast/toast";
import { convertUTCSec, formatDatetime, timePickerEdit } from "helper/dateFormatter";
import { convertHexToImage } from "helper/hexToImage";
import TimePicker from "components/datepicker/timepicker";
import { getAllpatron, getAllelasticity, createConfiguration, updateConfiguration } from "modules/configuration";
import { getAllpatronsucessrespone, getAllpatronfailResponse, getAllelasticitysucessrespone, getAllelasticityfailResponse, configurationSuccessresponse, configurationFailresponse } from "modules/types/configuration.model";
import { createSelect } from "helper/selectOptions";
import { getAllTenant } from "modules/client";
import { createWorkshopfailResponse, createWorkshopsucessResponse, getAllTenantfailResponse, getAllworkshopfailResponse, getAllworkshopsuccessResponse, getWorkshopByidSuccessResponse, getallTenantsuccessResponse, workshopPayload } from "modules/types/workshops.model";
import { getWorkshopbyId, updateWorkshop } from "modules/workshop";
import {createWorkshop as WorkshopCreation} from "modules/workshop"


interface Option {
  label: string;
  value: string;
}

interface FormField {
  label: string;
  id: string;
  type: "text" | "select" | "date";
  placeholder?: string;
  helperText: string;
  isRequired: boolean;
  options?: Option[];
  span: number;
}

type FormData = {
  [key: string]: string | Option | null;
};

type FormErrors = {
  [key: string]: string;
};

const CreateWorkShop = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({});
  const [errors, setErrors] = useState<FormErrors>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectOptions, setselectOptions] = useState<Record<string, any>>({});
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [intialdata, setIntialData] = useState<Record<string, any>>({});
  const [acronym, setAcronym] = useState("");
  const [url, setUrl] = useState("");
  const location = useLocation();
  const queryParam = useParams();
  const showToast = useCommonToast();
  const isEditable = location.pathname.includes("edit")
  const navigate = useNavigate();
  const baseUrl = window.location.href.split(window.location.pathname)[0]
  const normalLink = `${baseUrl}/play/${acronym}`;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const CustomMenu = (props: any) => {
    return (
      <components.Menu {...props}>
        {props.children}
        <Button
          onClick={() =>
            navigate("/configuration/create", {
              state: { redirect: true },
            })
          }
          width="100%"
          bg="transparent"
          textColor="#0B389E"
          padding={2}
          cursor="pointer"
          borderRadius={0}
          borderTop="1px"
          borderColor="gray.300"
        >
          Create New Data
        </Button>
      </components.Menu>
    );
  };

  const formFields: FormField[] = [
    {
      label: "Workshop Name",
      id: "name",
      type: "text",
      placeholder: "Enter the Workshop name",
      helperText: "",
      isRequired: true,
      span: 2,
    },
    {
      label: "Select Client",
      id: "tenant_id",
      type: "select",
      helperText: "Select the name of client",
      isRequired: true,
      span: 1,
      options: selectOptions["clientName"],
    },
    {
      label: "Game Rounds",
      id: "max_rounds",
      type: "select",
      helperText: "Select the maximum number of rounds",
      isRequired: true,
      span: 1,
      options: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
      ],
    },
    {
      label: "Game 3 - Price Elasticity",
      id: "price_elasticity_id",
      type: "select",
      helperText: "Select the game configuration for the workshop",
      isRequired: true,
      span: 2,
      options: selectOptions["priceElasticity"],
    },
    {
      label: "Game 3 - Time to Patron",
      id: "patron_id",
      type: "select",
      helperText: "Select the game configuration for the workshop",
      isRequired: true,
      span: 2,
      options: selectOptions["timePatron"],
    },
    {
      label: "Start Date",
      id: "start_time",
      type: "date",
      placeholder: "Select Start Date",
      helperText: "Enter the start date of workshop",
      isRequired: true,
      span: 1,
    },
    {
      label: "End Date",
      id: "end_time",
      type: "date",
      placeholder: "Select End Date",
      helperText: "Enter the end date of workshop",
      isRequired: true,
      span: 1,
    },
  ];

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: "#FFFFFF4D",
    }),
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: "20vh",
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#2647B0" : state.isSelected ? "#2647B080" : provided.backgroundColor,
      color: state.isFocused ? "white" : provided.color,
      '&:hover': {
        backgroundColor: "#2647B0",
        color: "white",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.selectProps.isDisabled ? "#718096" : provided.color,
    }),
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    formFields.forEach((field) => {
      if (field.isRequired && !formData[field.id]) {
        newErrors[field.id] = `${field.label} is required`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const transformWorkshopData = (originalData) => {
    return {
      tenant_id: originalData["tenant_id"]["value"],
      price_elasticity_id: originalData["price_elasticity_id"]["value"],
      patron_id: originalData["patron_id"]["value"],
      name: originalData.name.trim(),
      start_time: convertUTCSec(originalData.start_time),
      end_time: convertUTCSec(originalData.end_time),
      timezone_str:timeZone,
      game_type:"GAME3",
      max_rounds: originalData["max_rounds"]["value"],
    };
  };

  const closeModal = () =>{
    setIsModalOpen(false)
    navigate("/workshop/list")
  }  

  const createWorkshop = async (originalWorkshopData) => {
    const transformedData = transformWorkshopData(originalWorkshopData);

    let workShopData = {};
    Object.entries(transformedData).forEach(([key, value]) => {
      workShopData[key]=value;
    });
    workShopData["request_url"]=baseUrl;
    let resp;
    if(isEditable){
      updateWorkshop(workShopData,queryParam.id).then((response : createWorkshopsucessResponse | createWorkshopfailResponse)=>{
        const WorkshopDetail = response as createWorkshopsucessResponse
        workShopData["workshop_id"]=WorkshopDetail.id;
        updateConfiguration(workShopData,intialdata.configs.id).then((response:configurationSuccessresponse | configurationFailresponse)=>{
          resp = response as configurationSuccessresponse
          showToast("Success", resp?.message || `Workshop ${isEditable?"Update ":"Creation "}Successful`, "success");
          resetForm();
          isEditable?navigate("/workshop/list"):null;
        }).catch((error)=>{
          showToast("Error", error.response?.data?.detail || error.response?.data?.error, "error");
          throw error;
        })
      }).catch((error)=>{
        showToast("Error", error.response?.data?.detail || error.response?.data?.error, "error");
        throw error;
      }).finally(()=>{
        setIsLoading(false);
      })
    }else{
      WorkshopCreation(workShopData).then((response : createWorkshopsucessResponse | createWorkshopfailResponse)=>{
        const WorkshopDetail = response as createWorkshopsucessResponse
        setAcronym(WorkshopDetail.acronym)
        const url = convertHexToImage(WorkshopDetail.image_binary)
        setUrl(url)
        workShopData["workshop_id"]=WorkshopDetail.id;
        createConfiguration(workShopData).then((response: configurationSuccessresponse | configurationFailresponse)=>{
          resp = response as configurationSuccessresponse
          setIsModalOpen(true);
          showToast("Success", resp?.message || `Workshop ${isEditable?"Update ":"Creation "}Successful`, "success");
          resetForm();
          isEditable?navigate("/workshop/list"):null;
        }).catch((error)=>{
          showToast("Error", error.response?.data?.detail || error.response?.data?.error, "error");
          throw error;
        })
      }).catch((error)=>{
        showToast("Error", error.response?.data?.detail || error.response?.data?.error, "error");
        throw error;
      }).finally(()=>{
        setIsLoading(false);
      })
    }
    return resp;
  };

  const handleSubmit = async (): Promise<void> => {
    if (validateForm()) {
      const currentDate = new Date();
      const startDate = new Date(formData?.start_time as string);
      const endDate = new Date(formData?.end_time as string);
      const minStartDate = new Date(currentDate.getTime() - 60000);
      if (!isEditable && startDate < minStartDate) {
        showToast("Error", "Start date and time must be in the future", "error");
        return;
      }
      if (formData.start_time && formData.end_time) {
        if (endDate <= startDate) {
          showToast("Error", "End date and time must be after the start date and time", "error");
          return;
        }
      }
      setIsLoading(true);
      createWorkshop(formData)
    } else {
      showToast("Error","Please fill in all required fields." , "error");
    }
  };

  const resetForm = (): void => {
    if(isEditable){
      setFormData((prev) => ({
        ...prev,
        name: "",
        price_elasticity_id: {
          label: "",
          value: "",
        },
        patron_id: {
          label: "",
          value: "",
        },
        end_time: "",
      }));
    }else{
      setFormData({});
    }
    setErrors({});
  };

  const handleInputChange = (
    fieldId: string,
    value: string | Option | null
  ): void => {
    let formattedValue = value;
    setFormData((prev) => ({
      ...prev,
      [fieldId]: formattedValue,
    }));
    setErrors((prev) => ({
      ...prev,
      [fieldId]: "",
    }));
  };


  const renderField = (field: FormField): JSX.Element => {
    switch (field.type) {
      case "select":
        return (
          <Select<Option>
            id={`select-${field.id}`}
            classNamePrefix="select"
            name={field.id}
            isLoading={isLoading}
            isClearable={true}
            isSearchable={true}
            options={field.options}
            // components={{ Menu: CustomMenu }}
            styles={customStyles}
            value={(formData[field.id] as Option) || null}
            onChange={(selectedOption) =>
              {handleInputChange(field.id, selectedOption)}
            }
            isDisabled={((field.id === "tenant_id") && isEditable)}
            menuPortalTarget={document.body}
            menuPosition="fixed"
          />
        );
      case "date":
        return (
            <TimePicker
              field={field}
              formData={formData}
              handleInputChange={handleInputChange}
              isEditable={isEditable}
            />
        );
      default:
        return (
          <Input
            placeholder={field.placeholder}
            bg="InputBoxbg"
            value={(formData[field.id] as string) || ""}
            onChange={(e) => handleInputChange(field.id, e.target.value)}
            _placeholder={{ color: "PlaceHolderColor" }}
          />
        );
    }
  };

  function createSelectOptions(data1, data2, data3) {
    const selectOptions = {
      clientName: [],
      priceElasticity: [],
      timePatron: [],
    };
    data1.forEach((item) => {
      selectOptions.clientName.push({
        label: item.name,
        value: item.id,
      });
    });

    data2.forEach((item) => {
      selectOptions.priceElasticity.push({
        label: item.name,
        value: item.id,
      });
    });

    data3.forEach((item) => {
      selectOptions.timePatron.push({
        label: item.name,
        value: item.id,
      });
    });

    return selectOptions;
  }

  const getWorkshopOptions = async () => {
    try {
      const promises = [
        getAllpatron()
          .then((response: getAllpatronsucessrespone[] | getAllpatronfailResponse) => {
            const patronResponse = response as getAllpatronsucessrespone[];
            const selectedOptions = createSelect(patronResponse);
            setselectOptions(prev => ({
              ...prev,
              timePatron: selectedOptions
            }));
          }),
        getAllelasticity()
          .then((response: getAllelasticitysucessrespone[] | getAllelasticityfailResponse) => {
            const elasticityResponse = response as getAllelasticitysucessrespone[];
            const selectedOptions = createSelect(elasticityResponse);
            setselectOptions(prev => ({
              ...prev,
              priceElasticity: selectedOptions
            }));
          }),
        getAllTenant()
        .then((response: getallTenantsuccessResponse[] | getAllTenantfailResponse) => {
          const elasticityResponse = response as getallTenantsuccessResponse[];
          const selectedOptions = createSelect(elasticityResponse);
          setselectOptions(prev => ({
            ...prev,
            clientName: selectedOptions
          }));
        })
      ];
      await Promise.all(promises);
        if(isEditable){
          getWorkshopbyId(queryParam.id).then((resp : getWorkshopByidSuccessResponse | getAllworkshopfailResponse)=>{
            const userData = resp as getWorkshopByidSuccessResponse
            setIntialData(userData)
            setFormData((prev) => ({
              ...prev,
              name: userData.name,
              tenant_id: {
                label: userData.configs.tenant__name,
                value: userData.configs.tenant_id,
              },
              price_elasticity_id: {
                label: userData.configs.price_elasticity__name,
                value: userData.configs.price_elasticity_id,
              },
              patron_id: {
                label: userData.configs.patron__name,
                value: userData.configs.patron_id,
              },
              start_time: (userData.start_time),
              end_time: (userData.end_time),
              max_rounds: {
                label: userData.configs.max_rounds,
                value: userData.configs.max_rounds,
              },
            }));
          })
        }
    } catch (error) {
      console.error("Error fetching configuration data:", error);
    } finally {
      setIsInitialLoading(false);
    }
  };

  useEffect(() => {
    resetForm();
  }, [isEditable]);

  useEffect(() => {
    setIsInitialLoading(true);
    getWorkshopOptions();
  }, []);

  return (
    <>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <Box
          id="addclient-form"
          overflowX="auto"
          borderRadius="20px"
          display="flex"
          flexDirection="column"
          flex={1}
          fontFamily="Poppins"
          mx={{ base: "0vw", md: "18vw" }}
        >
          <QRLinkModal
            isOpen={isModalOpen}
            onClose={() => {closeModal()}}
            qrLink={url}
            normalLink={normalLink}
            title="Scan or Click"
          />
          <Flex direction="column" flex={1}>
            <Flex
              bg="TableHeaderbg"
              justify="space-between"
              align="center"
              py="10px"
              px="20px"
              textColor="#0B389E"
              fontWeight={600}
              fontSize="18px"
            >
              {isEditable ?"Edit ":"Create "}Workshop
            </Flex>
            <Flex
              bg="Containerbg"
              flex={1}
              py="10px"
              px="20px"
              direction="column"
              justifyContent="space-between"
            >
              <Flex id="form-box" gap={4} direction="column">
                <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                  {formFields.map((field) => (
                    <GridItem key={field.id} colSpan={field.span}>
                      <FormControl
                        isRequired={field.isRequired}
                        isInvalid={!!errors[field.id]}
                        onFocus={() => {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            [field.id]: "",
                          }));
                        }}
                      >
                        <FormLabel>{field.label}</FormLabel>
                        {renderField(field)}
                        <FormHelperText
                          color={errors[field.id] ? "red.500" : "inherit"}
                        >
                          {errors[field.id] || field.helperText}
                        </FormHelperText>
                      </FormControl>
                    </GridItem>
                  ))}
                </Grid>
              </Flex>
              <Flex
                id="button-box"
                justifyContent="flex-end"
                alignItems="flex-end"
                gap={3}
                mt={4}
              >
                <Button
                  borderRadius="8px"
                  bg="white"
                  textColor="#0B389E"
                  border="2px"
                  borderColor="#5C85DC"
                  py={2}
                  px={4}
                  onClick={() => navigate("/workshop/list")}
                >
                  Cancel
                </Button>
                <Button
                  borderRadius="8px"
                  bgGradient="linear(to-b, #5C86DC,#2647B0)"
                  textColor="white"
                  onClick={handleSubmit}
                  isLoading={isLoading}
                  py={2}
                  px={4}
                >
                  Confirm
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};

export default CreateWorkShop;
