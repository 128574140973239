import { ColumnDef } from "@tanstack/react-table";
import GenericDataTable from "../../../../components/table/ListingTable";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Loader from "components/loader/loader";
import { formatDate } from "helper/dateFormatter";
import { getAllTenant } from "modules/client";

interface DataType {
  id: string;
  name: string;
  createdDate: string;
  start_date: string;
  end_date: string;
  // ... other fields
}

const columns: ColumnDef<DataType>[] = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "Start Date",
    accessorKey: "start_date",
  },
  {
    header: "End Date",
    accessorKey: "end_date",
  },
  {
    header: "Created Date",
    accessorKey: "created_at",
  },
];

export const ClientListings = () => {
  const [tableDate, setTableData] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(false);

  const navigate = useNavigate();

  const getClientListing = async () => {
      setIsInitialLoading(true);
      getAllTenant()
      .then((resp) => {
        if (Array.isArray(resp)) {
          const clientListingData = (resp as Array<{ 
            id: number;
            name: string;
            uuid: string;
            description: string;
            is_active: boolean;
            start_date: string; 
            end_date: string;   
            created_at: string;
          }>).
          map((item) => ({
            ...item,
            start_date: formatDate(item?.start_date), 
            end_date: formatDate(item?.end_date),
            created_at : formatDate(item?.created_at)
          }));
    
          setTableData(clientListingData); 
        }
      })
      .catch((error) => {
        console.error("Error fetching tenants:", error);
      })
      .finally(()=>{
      setIsInitialLoading(false); 
      });
  };

  useEffect(() => {
    setIsInitialLoading(true);
    getClientListing();
  }, []);

  const handleCreateItem = () => {
    navigate("/client/create");
  };

  const handleViewItem = (item: DataType) => {
    navigate(`/client/view/${item.id}`);
  };

  const handleEditItem = (item: DataType) => {
    navigate(`/client/edit/${item.id}`);
  };

  return (
    <>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <GenericDataTable
          data={tableDate}
          columns={columns}
          onCreateItem={handleCreateItem}
          onViewItem={handleViewItem}
          onEditItem={handleEditItem}
          createButtonText="Create Client"
          isViewable={false}
          similarWidth={false}
          actionsPresent={true}
          showTopBar={true}
        />
      )}
    </>
  );
};
