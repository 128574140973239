import { workShop } from "../service/workShops";
import {
  getAllworkshopsuccessResponse,
  getAllworkshopfailResponse,
  workshopPayload,
  createWorkshopsucessResponse,
  createWorkshopfailResponse,
  getWorkshopByidSuccessResponse
  

} from "./types/workshops.model";

export const getAllWorkshop = async (): Promise<
  getAllworkshopsuccessResponse | getAllworkshopfailResponse
> => {
  try {
    const response = await workShop.getworkShop();

    const data: getAllworkshopsuccessResponse = response.data;
    if (response.status === 200) {
      return data;
    }
  } catch (getAllworkshopfailResponse) {
    throw getAllworkshopfailResponse
  }
};

export const getWorkshopbyId = async (id:string): Promise<
getWorkshopByidSuccessResponse | getAllworkshopfailResponse
> => {
  try {
    const response = await workShop.getByidworkshop(id);

    const data: getWorkshopByidSuccessResponse = response.data;
    if (response.status === 200) {
      return data;
    }
  } catch (getAllworkshopfailResponse) {
    throw getAllworkshopfailResponse
  }
};



export const createWorkshop = async (workshopPayload): Promise<createWorkshopsucessResponse|createWorkshopfailResponse> => {
  try {
    const response = await workShop.createworkShop(workshopPayload);

    const data: createWorkshopsucessResponse = response.data;

   if (response.status === 201) {
      return data;
      }  
  } catch (createWorkshopfailResponse) {
    throw createWorkshopfailResponse

  }
};


export const createInstantworkShop = async (workshopPayload): Promise<createWorkshopsucessResponse|createWorkshopfailResponse> => {
  try {
    const response = await workShop.createInstantworkShop(workshopPayload);

    const data: createWorkshopsucessResponse = response.data;

   if (response.status === 201) {
      return data;
      }  
  } catch (createWorkshopfailResponse) {
    throw createWorkshopfailResponse

  }
};

export const updateWorkshop = async (workshopPayload,queryParam): Promise<createWorkshopsucessResponse|createWorkshopfailResponse> => {
  try {
    const response = await workShop.putByidworkShop(workshopPayload,queryParam);

    const data: createWorkshopsucessResponse = response.data;

   if (response.status === 200) {
      return data;
      }  
  } catch (createWorkshopfailResponse) {
    throw createWorkshopfailResponse

  }
};