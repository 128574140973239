import axiosClient from "config/apiClient";
import { fetchData } from "helper/fetchData";

export const workShop = {
   getworkShop: () => axiosClient.get("/workshop/get/"),
   createworkShop: (workshopPayload) => axiosClient.post("/workshop/manage/",workshopPayload),
   createInstantworkShop: (workshopPayload) => axiosClient.post("workshop/instant/",workshopPayload),
   getByidworkshop:(queryParam) => axiosClient.get(`/workshop/get/?pk=${queryParam}`),
   putByidworkShop :(workshopPayload,queryParam) => axiosClient.put(`workshop/manage/${queryParam}/`,workshopPayload),
   fetchWorkshop:(id) => fetchData(`workshop/get/?pk=${id}`),

   
//    workshop/manage/${queryParam.id}/
//    putByidworkshop: (queryParams,putClientpayload) =>axiosClient.put(`/tenant/manage/?id=${queryParams}`, putClientpayload,header),


    // getgame3 : () => axiosClient.get("game3/getgame3"),
    // getAllPeRecords : () => axiosClient.get("game3/getAllPeRecords/"),
    // postgame3: (game3Payload) => axiosClient.post("game3/postgame3",game3Payload)
};


