import React, { useState, useCallback, useEffect } from "react";
import { useDropzone, FileRejection, DropzoneOptions } from "react-dropzone";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Text,
  VStack,
  Image,
  BoxProps,
  FlexProps,
  Switch,
  GridItem,
  Grid,
} from "@chakra-ui/react";
import Select from "react-select";
import uploadImage from "../../../../assets/systemadmin/upload.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useCommonToast } from "components/toast/toast";
import Loader from "components/loader/loader";
import { createElasticity, createTimepatron, getByidElasticity, getByidTimepatron, putElasticity, putTimePatron } from "modules/configuration";
import { configurationFailresponse, configurationSuccessresponse, getByidElasticityfailResponse, getByidElasticitysuccessresponse, getByidTimepatronfailResponse, getByidTimepatronsuccessresponse, putConfigurationFailresponse, putConfigurationsuccessresponse, putTimePatronFailresponse, putTimePatronsuccessresponse, timePatronFailresponse, timePatronSuccessresponse } from "modules/types/configuration.model";
import { getAllTenant } from "modules/client";
import { getAllTenantfailResponse, getallTenantsuccessResponse } from "modules/types/workshops.model";
import { createSelect } from "helper/selectOptions";

interface FileUploadProps {
  onFileUpload: (file: File) => void;
  label: string;
  filename?: string;
  error?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  label,
  filename,
  error,
}) => {
  const showToast = useCommonToast();
  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles[0]) {
        onFileUpload(acceptedFiles[0]);
      }
      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach((file) => {
          let errorMessage = "File upload failed.";
          if (file.errors[0]?.code === "file-too-large") {
            errorMessage = "File is too large. Maximum size is 5MB.";
          } else if (file.errors[0]?.code === "file-invalid-type") {
            errorMessage = "Invalid file type. Please upload a CSV file.";
          }
          showToast("Error", errorMessage, "error");
        });
      }
    },
    [onFileUpload, showToast]
  );

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    multiple: false,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    minSize: 0,
    maxSize: 5242880,
  };

  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropzoneOptions);

  const rootProps: BoxProps = {
    ...getRootProps(),
    p: 4,
    border: "2px dashed",
    borderColor: isDragActive ? "gray.700" : error ? "red.500" : "#09389F",
    borderRadius: "md",
    textAlign: "center",
    cursor: "pointer",
    width: { base: "100%", md: "60%" },
    h: "100%",
    bg: "#0A389F1A",
  };

  const flexProps: FlexProps = {
    justifyContent: "center",
    alignItems: "center",
    h: "100%",
    flexDirection: "column",
  };

  return (
    <Flex flexDirection={{ base: "column", md: "row" }} flex={1} h="80%">
      <Box {...rootProps}>
        <input {...getInputProps()} />
        <Flex {...flexProps}>
          {filename ? (
            <>
              <Image
                src={uploadImage}
                h="30%"
                w="30%"
                objectFit="contain"
                alt="Upload"
              />
              <Text>File Uploaded : {filename}</Text>
            </>
          ) : (
            <>
              <Image
                src={uploadImage}
                h="30%"
                w="30%"
                objectFit="contain"
                alt="Upload"
              />
              {isDragActive ? (
                <Text>Drop the CSV file here</Text>
              ) : (
                <Text>{label}</Text>
              )}
            </>
          )}
        </Flex>
      </Box>
      <Box
        display="flex"
        width={{ base: "100%", md: "40%" }}
        h="100%"
        justifyContent="space-evenly"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
      >
        <Text>Supported Formats : CSV</Text>
        <Text>Maximum Size : 5 MB</Text>
      </Box>
    </Flex>
  );
};

interface FormErrors {
  configName?: string;
  file1?: string;
  "game-name"?: string;
  "file-type-conf"?: string;
}

const CreateConfigurationForm: React.FC = () => {
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [configName, setConfigName] = useState<string>("");
  const [file1, setFile1] = useState<File | null>(null);
  const [errors, setErrors] = useState<FormErrors>({});
  const [selectOptions, setselectOptions] = useState<Record<string, any>>({});
  const [selectedConfigurations, setSelectedConfigurations] = useState<
    Record<string, any>
  >({});
  const [defaultCheck, setDefaultCheck] = useState(false)
  const [globalCheck, setGlobalCheck] = useState(false)
  const queryParam = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const showToast = useCommonToast();
  const { type } = location.state || {};
  const handleFile1Upload = (file: File) => setFile1(file);
  const isEditable = location.pathname.includes("edit");
  const gameDataType = [
    {
      label: "Game 1",
      value: "Game1",
      type: [
        { label: "Price Elasticity", value: "PriceElasticity" },
        { label: "Time to Patrons", value: "TimetoPatrons" },
      ],
    },
    {
      label: "Game 2",
      value: "Game2",
      type: [
        { label: "Price Elasticity", value: "PriceElasticity" },
        { label: "Time to Patrons", value: "TimetoPatrons" },
      ],
    },
    {
      label: "Game 3",
      value: "Game3",
      type: [
        { label: "Price Elasticity", value: "PriceElasticity" },
        { label: "Time to Patrons", value: "TimetoPatrons" },
      ],
    },
  ];

  const formFields = [
    {
      label: "Client Name",
      id: "client-name",
      helperText: "Select the Client",
      options: selectOptions.clientName,
      required:false,
    },
    {
      label: "Game",
      id: "game-name",
      helperText: "Select the name of the game",
      options: gameDataType.map(({ label, value }) => ({ label, value })),
      required:true,

    },
    {
      label: "File Type",
      id: "file-type-conf",
      helperText: "Select the type of configuration",
      options: [],
      required:true,
    },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#FFFFFF4D",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "30vh",
      overflowY: "auto",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#2647B0" : state.isSelected ? "#2647B080" : provided.backgroundColor,
      color: state.isFocused ? "white" : provided.color,
      '&:hover': {
        backgroundColor: "#2647B0",
        color: "white",
      },
    }),
  };

  const resetForm = () => {
    setConfigName("");
    setFile1(null);
    setErrors({});
    setSelectedConfigurations({});
  };

  const handleGlobalCheck = (e) => {
    setGlobalCheck(e.target.checked)
    if(!globalCheck){
      setSelectedConfigurations(({ "client-name": _, ...rest }) => rest);
    }
  }

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    if (!configName.trim()) {
      newErrors.configName = "Configuration name is required";
    }
    if (!file1) {
      newErrors.file1 = "Configuration file is required";
    }
    formFields.forEach((field) => {
      if (!selectedConfigurations[field.id] && field.id !== "client-name") {
        newErrors[field.id] = `${field.label} is required`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const uploadConfiguration = async (
    configData,
    queryParam,
    isEditable: boolean
  ) => {
      if (isEditable && queryParam.id) {
        configData.append("id", queryParam.id);
      }
      const configurationType = configData.get("type");
      const queryParams = queryParam.id;
      if (configurationType === "PriceElasticity") {
        if (!isEditable) {
          createElasticity(configData).then(
            (
              resp: configurationSuccessresponse | configurationFailresponse
            ) => {
              const response = resp as configurationSuccessresponse;
              showToast("Success", response.message, "success");
              resetForm();
              navigate("/configuration/list");
            }
          ).catch((error)=>{
            showToast("Error", error.response?.data?.error || error.response?.data?.message, "error");
            throw error
          }).finally(()=>{
            setIsLoading(false);
            });
        } else {
          putElasticity(queryParams, configData).then(
            (resp:| putConfigurationsuccessresponse| putConfigurationFailresponse) => {
              const response = resp as putConfigurationsuccessresponse;
              showToast("Success", response.message, "success");
              resetForm();
              navigate("/configuration/list");
            }
          ).catch((error)=>{
            showToast("Error", error.response?.data?.error || error.response?.data?.message, "error");
            throw error
          }).finally(()=>{
            setIsLoading(false);
            });
        }
      } else {
        if (!isEditable) {
          createTimepatron(configData).then(
            (resp: timePatronSuccessresponse | timePatronFailresponse) => {
              const response = resp as timePatronSuccessresponse;
              showToast("Success", response.message, "success");
              resetForm();
              navigate("/configuration/list");
            }
          ).catch((error)=>{
            showToast("Error", error.response?.data?.error || error.response?.data?.message, "error");
            throw error
          }).finally(()=>{
            setIsLoading(false);
            });
        } else {
          putTimePatron(queryParams, configData).then(
            (
              resp: putTimePatronsuccessresponse | putTimePatronFailresponse
            ) => {
              const response = resp as putTimePatronsuccessresponse;
              showToast("Success", response.message, "success");
              resetForm();
              navigate("/configuration/list");
            }
          ).catch((error)=>{
            showToast("Error", error.response?.data?.error || error.response?.data?.message, "error");
            throw error
          }).finally(()=>{
            setIsLoading(false);
            });
        }
      }
  };
  
  const handleSubmit = async (): Promise<void> => {
      if (!validateForm()) {
        showToast("Error", "Please fill in all required fields.", "error");
        return;
      }
      setIsLoading(true);
      const formData = new FormData();
      formData.append("name", configName.trim());
      if (file1) {
        formData.append("file", file1);
      }
      formData.append("game", selectedConfigurations["game-name"].value);
      formData.append("type", selectedConfigurations["file-type-conf"].value);
      formData.append("is_default", defaultCheck.toString());
      if (selectedConfigurations["client-name"] && globalCheck)
        formData.append(
          "tenant_id",
          selectedConfigurations["client-name"].value
        );
      uploadConfiguration(formData, queryParam, isEditable)
  };

  const fetchConfigurationDetails = async (queryParams) => {
      if (type !== "Time to Patron") {
        getByidElasticity(queryParams.id)
          .then((response : getByidElasticitysuccessresponse | getByidElasticityfailResponse) => {
            const userData = response as getByidElasticitysuccessresponse
            setConfigName(userData[0].name);
            setDefaultCheck(userData[0].is_active)
            userData[0].is_global = true
            setGlobalCheck(userData[0].is_global)
            setFile1((prev) => ({
              ...prev,
              name: userData[0].uploaded_file_name,
            }));
            if (userData[0].tenant__name && userData[0].tenant_id) {
              setSelectedConfigurations((prev) => ({
                ...prev,
                "client-name": {
                  label: userData[0].tenant__name,
                  value: userData[0].tenant_id,
                },
              }));
            }
            setSelectedConfigurations((prev) => ({
              ...prev,
              "game-name": {
                label: "Game 3",
                value: "Game3",
              },
              "file-type-conf": {
                label: type,
                value: type.replace(/\s/g, ""),
              },
          }));
          })
          .catch((error) => {
            throw error;
          }).finally(()=>{
            setIsInitialLoading(false)
          });

        setSelectedConfigurations((prev) => ({
          ...prev,
          "game-name": {
            label: "Game 3",
            value: "Game3",
          },
          "file-type-conf": {
            label: type,
            value: type.replace(/\s/g, ""),
          },
        }));
      } else {
        getByidTimepatron(queryParams.id)
          .then((response:getByidTimepatronsuccessresponse | getByidTimepatronfailResponse) => {
            const userData = response as getByidTimepatronsuccessresponse
            setConfigName(userData[0].name);
            setFile1((prev) => ({
              ...prev,
              name: userData[0].uploaded_file_name,
            }));
            setSelectedConfigurations((prev) => ({
              ...prev,
              "game-name": {
                label: "Game 3",
                value: "Game3",
              },
              "file-type-conf": {
                label: type,
                value: type.replace(/\s/g, ""),
              },
          }));
          })
          .catch((error) => {
            throw error;
          }).finally(()=>{
            setIsInitialLoading(false)
          });
      }
  };

  useEffect(() => {
    resetForm();
  }, [isEditable]);

  useEffect(() => {
    if (isEditable) {
      setIsInitialLoading(true);
      fetchConfigurationDetails(queryParam);
    }
    getAllTenant()
    .then((response: getallTenantsuccessResponse[] | getAllTenantfailResponse) => {
      const elasticityResponse = response as getallTenantsuccessResponse[];
      const selectedOptions = createSelect(elasticityResponse);
      setselectOptions(prev => ({
        ...prev,
        clientName: selectedOptions
      }));
    })
  }, []);

  const handleGameChange = (selectedOption, fieldId) => {
    setSelectedConfigurations((prev) => {
      const newConfig = { ...prev, [fieldId]: selectedOption };
      if (fieldId === "game-name") {
        newConfig["file-type-conf"] = null; // Reset file type when game changes
      }
      return newConfig;
    });
  };

  return (
    <>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <Box
          id="addconfiguration-form"
          overflowX="auto"
          bg={"#FFFFFF10"}
          borderRadius="20px"
          display="flex"
          flexDirection="column"
          flex={1}
          fontFamily="Poppins"
          mx={{ base: "0vw", md: "18vw" }}
        >
          <Flex direction="column" flex={1}>
            <Flex
              bg="TableHeaderbg"
              justify="space-between"
              align="center"
              py="10px"
              px="20px"
              textColor="#0B389E"
              fontWeight={600}
              fontSize="18px"
            >
              {isEditable ? "Edit " : "Create "} Configuration
            </Flex>
            <Flex
              bg="Containerbg"
              flex={1}
              py="10px"
              px="20px"
              direction="column"
              justifyContent="space-between"
            >
              <VStack id="form-box" spacing={4} align="stretch" h="100%">
                <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                  <GridItem colSpan={1}>
                    <FormControl display="flex" flexDirection={"column"}>
                      <FormLabel fontWeight={500}>Global</FormLabel>
                      <Flex gap={2}>
                      <Switch
                        isChecked={globalCheck}
                        onChange={(e) => handleGlobalCheck(e)}
                        size="sm" // Size is adjustable: 'sm', 'md', 'lg'
                        opacity={1}
                        sx={{
                          ".chakra-switch__track": {
                            bg: "transparent",
                            borderStyle:'solid',
                            borderColor: globalCheck ? "#0C389E" : "black",
                            borderWidth:"2px",
                            width: "22px",
                            height: "14px",
                          },
                          ".chakra-switch__thumb": {
                            bg: globalCheck ? "#0C389E" : "black",
                            width: "10px",
                            height: "10px",
                            margin: "2px",
                          },
                        }}
                      />
                      <FormLabel htmlFor="email-alerts" ml="2" mb="0" fontSize={"14px"}>
                        To make the configuration available to all clients
                      </FormLabel>
                      </Flex>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={1}>
                  <FormLabel fontWeight={500}>Default</FormLabel>
                    <FormControl display="flex" alignItems="center">
                      <Flex gap={2}>
                      <Switch
                        isChecked={defaultCheck}
                        onChange={(e) => setDefaultCheck(e.target.checked)}
                        size="sm" // Size is adjustable: 'sm', 'md', 'lg'
                        sx={{
                          ".chakra-switch__track": {
                            bg: "transparent",
                            borderStyle:'solid',
                            borderColor: defaultCheck ? "#0C389E" : "black",
                            borderWidth:"2px",
                            width: "22px",
                            height: "14px",
                          },
                          ".chakra-switch__thumb": {
                            bg: defaultCheck ? "#0C389E" : "black",
                            width: "10px",
                            height: "10px",
                            margin: "2px",
                          },
                        }}
                      />
                      <FormLabel htmlFor="email-alerts" ml="2" mb="0" fontSize={"14px"}>
                        {`To make the configuration default ${
                          globalCheck ? "Globally" : "for Tenant"
                        }`}
                      </FormLabel>
                      </Flex>
                    </FormControl>
                  </GridItem>
                </Grid>
                <FormControl isRequired isInvalid={!!errors.configName}>
                  <FormLabel fontWeight={500}>Configuration Name</FormLabel>
                  <Input
                    placeholder="Enter the Configuration name"
                    bg="InputBoxbg"
                    value={configName}
                    _placeholder={{ color: "PlaceHolderColor" }}
                    onChange={(e) => setConfigName(e.target.value)}
                    onFocus={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        configName: "",
                      }));
                    }}
                  />
                  <FormHelperText
                    color={errors.configName ? "red.500" : "inherit"}
                  >
                    {/* {errors.configName ||
                      "Enter the Configuration name you want to create"} */}
                  </FormHelperText>
                </FormControl>
                {formFields.map((field) => (
                  <FormControl
                    key={field.id}
                    isRequired={field.required}
                    isInvalid={!!errors[field.id]}
                    onFocus={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        [field.id]: "",
                      }));
                    }}
                    hidden={field.id==="client-name" && globalCheck}
                  >
                    <FormLabel>{field.label}</FormLabel>
                    <Select
                      id={`select-${field.id}`}
                      classNamePrefix="select"
                      name={field.id}
                      isLoading={isLoading}
                      isClearable={true}
                      isSearchable={true}
                      options={
                        field.id === "file-type-conf" &&
                        selectedConfigurations["game-name"]
                          ? gameDataType.find(
                              (game) =>
                                game.value ===
                                selectedConfigurations["game-name"].value
                            )?.type || []
                          : field.options
                      }
                      styles={customStyles}
                      value={selectedConfigurations[field.id] || null}
                      onChange={(selectedOption) =>
                        handleGameChange(selectedOption, field.id)
                      }
                      isDisabled={
                        (field.id === "file-type-conf" &&
                          !selectedConfigurations["game-name"]) ||
                        isEditable
                      }
                      menuPortalTarget={document.body}
                      menuPosition={"fixed"}
                    />
                    <FormHelperText
                      color={errors[field.id] ? "red.500" : "inherit"}
                    >
                      {errors[field.id] || field.helperText}
                    </FormHelperText>
                  </FormControl>
                ))}
                <Flex flex={1}>
                  <FormControl
                    isRequired
                    isInvalid={!!errors.file1}
                    onFocus={() => {
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        file1: "",
                      }));
                    }}
                  >
                    <FileUpload
                      onFileUpload={handleFile1Upload}
                      label="Drag and drop or click to upload"
                      filename={file1?.name}
                      error={!!errors.file1}
                    />
                    {errors.file1 && (
                      <FormHelperText color="red.500">
                        {errors.file1}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Flex>
              </VStack>
              <Flex
                id="button-box"
                justifyContent="flex-end"
                alignItems="flex-end"
                gap={3}
                mt={4}
              >
                <Button
                  borderRadius="8px"
                  bg="white"
                  textColor="#0B389E"
                  border="2px"
                  borderColor="#5C85DC"
                  onClick={() => navigate("/configuration/list")}
                >
                  Cancel
                </Button>
                <Button
                  borderRadius="8px"
                  bgGradient="linear(to-b, #5C86DC,#2647B0)"
                  _hover={{ bgGradient: "linear(to-b, #4a86ff,#233d97)" }}
                  textColor="white"
                  onClick={handleSubmit}
                  isLoading={isLoading}
                >
                  Confirm
                </Button>
              </Flex>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
};


export default CreateConfigurationForm;