import React, { useEffect, useState } from "react";
import {
  PublicClientApplication,
  AccountInfo,
  AuthenticationResult,
} from "@azure/msal-browser";
import {
  PermissionfailResponse,
  PermissionsuccessResponse,
  loginfailResponse,
  loginsuccessResponse,
  tenantConfigSuccessResponse,
} from "modules/types/auth.model";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { handleIdpLogin, handlePermission } from "modules/auth";
import { useGameStore } from "store/store";
import { RiMicrosoftFill } from "react-icons/ri";

interface MicrosoftSignInProps {
  msalInstance: PublicClientApplication;
  configurationData: tenantConfigSuccessResponse;
  teamName:String
}

export const MicrosoftSignIn: React.FC<MicrosoftSignInProps> = ({
  msalInstance,
  configurationData,
  teamName
}) => {
  const [account, setAccount] = useState<AccountInfo | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { setauthData, setPermissionsList } = useGameStore();
  const navigate = useNavigate();

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await msalInstance.initialize();
        const currentAccounts = msalInstance.getAllAccounts();
        if (currentAccounts.length > 0) {
          setAccount(currentAccounts[0]);
        }
      } catch (error) {
        console.error("MSAL Initialization Error", error);
        toast.error("Failed to initialize Microsoft login");
      }
    };

    initializeMsal();
  }, [msalInstance]);

  const handleMicrosoftSignIn = async () => {
    try {
      setLoading(true);
      if (!msalInstance.initialize()) {
        await msalInstance.initialize();
      }
      const scopes = [configurationData.api_scope];
      const loginResponse: AuthenticationResult = await msalInstance.loginPopup(
        {
          prompt: "consent",
          scopes: scopes,
        }
      );

      if (loginResponse.account) {
        setAccount(loginResponse.account);

        const authResult = {
          tenant_id: loginResponse.account.tenantId,
          team_name: teamName,
        };

        const access_token = loginResponse.accessToken;
        const headers = {
          "X-Azure-Authorization": `Bearer ${access_token}`,
        };

        handleIdpLogin(authResult, headers)
          .then((response: loginsuccessResponse | loginfailResponse) => {
            const logindata = response as loginsuccessResponse;
            setauthData(logindata);
            toast.success(`Welcome, ${loginResponse.account.name}`);
            handlePermission()
              .then(
                (
                  response: PermissionsuccessResponse | PermissionfailResponse
                ) => {
                  const data = response as PermissionsuccessResponse;
                  setPermissionsList(data);
                  setLoading(false);
                  navigate("/about");
                }
              )
              .catch(() => {
                toast.error("You are not yet Authorised contact Admin");
                setLoading(false);
              });
          })
          .catch((error) => {
            toast.error(error.response.data.detail || "An unexpected error occurred during login");
            setLoading(false);
          });
      }
    } catch (error) {
      console.error("Microsoft Login Error", error);
      setLoading(false);
      if (error instanceof Error) {
        toast.error(`Login failed`);
      } else {
        toast.error("An unexpected error occurred during login");
      }
    }
  };

  return (
      <Button
        onClick={handleMicrosoftSignIn}
        isLoading={loading}
        colorScheme="blue"
        loadingText="Authenticating"
        variant={"outline"}
        leftIcon={<RiMicrosoftFill/>}
        className="w-full rounded-xl bg-[#2f2f2f] text-white hover:bg-[#444444]"
      >
        Sign in with Microsoft
      </Button>
  );
};
