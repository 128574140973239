

import { LineChartData } from "store/game2/game2Slice";
import {game2} from "../service/game2"
import {game2ProbabilitydistributionsuccessResponse,game2ProbabilitydistributionfailResponse,option,profitCalculationData,game2profitCalculationDatasuccessResponse,game2profitCalculationDatafailResponse} from "./types/game2.model"


export const game2Probabilitydistribution = async (option:option,isPlayable,acronym): Promise<game2ProbabilitydistributionsuccessResponse| game2ProbabilitydistributionfailResponse> => {
  let response;
  try {
    if(isPlayable && acronym){
      response = await game2.probabilitydistributionworkshop(option,acronym);
    }else{
      response = await game2.probabilitydistribution(option);
    }
    const data: game2ProbabilitydistributionsuccessResponse = response.data;

   if (response.status === 200) {
      return data;
      }  

  } catch (game2ProbabilitydistributionfailResponse) {
    throw game2ProbabilitydistributionfailResponse
  }
};


  export const game2profitcalculation = async (profitCalculationData :profitCalculationData,isPlayable,acronym): Promise<LineChartData|game2profitCalculationDatafailResponse> => {
    try {
      let response;
      if(isPlayable){
        response = await game2.profitcalculationworkshop(profitCalculationData,acronym);
      }else{
        response = await game2.profitcalculation(profitCalculationData);
      }
      const data: LineChartData = response.data;
      if (response.status === 200) {
        return data;
        }  
    } catch (game2profitCalculationDatafailResponse) {
      throw game2profitCalculationDatafailResponse
    }
  };



