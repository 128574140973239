import axiosClient from "config/apiClient";
import {
  configurationPayload,
  timePatronpayload,
  putConfigurationpayload,
  createConfigurationPayload
} from "../modules/types/configuration.model";
import { fetchData } from "helper/fetchData";

let header = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const configuration = {
  getPatrons: () => axiosClient.get("flow/metrics/get/patrons/"),
  getElasticity: () => axiosClient.get("flow/metrics/get/elasticity/"),
  createElasticity: (configurationPayload: configurationPayload) =>
    axiosClient.post(
      "flow/metrics/price/elasticity/",
      configurationPayload,
      header
    ),
  createTimepatrons: (timePatronpayload: timePatronpayload) =>
    axiosClient.post("/flow/metrics/patron/", timePatronpayload, header),
  createConfiguration: (payload : createConfigurationPayload) =>
  axiosClient.post("flow/metrics/configuration/", payload),
  updateConfiguration: (payload,id:string) =>
  axiosClient.put(`flow/metrics/configuration/${id}/`, payload),
  // RandomPostcall(payload, queryParam) : () => axiosClient.post(`random_postcall?${queryParam}`, payload)
  getByidElasticity: (queryParams) =>
    axiosClient.get(`flow/metrics/get/elasticity/?id=${queryParams}`),
  getByidTimepatron: (queryParams) =>
    axiosClient.get(`flow/metrics/get/patrons/?id=${queryParams}`),
  putElasticity: (queryParams, putConfigurationpayload:putConfigurationpayload) =>
    axiosClient.put(
      `flow/metrics/price/elasticity/${queryParams}/`,
      putConfigurationpayload,
      header
    ),
  putTimepatron: (payload,queryParams) =>
    axiosClient.put(`/flow/metrics/patron/${queryParams}/`,payload),
  fetchSpecificPatron :(patron_id) => fetchData(`/flow/metrics/get/specific/patron/?id=${patron_id}`),
  fetchSpecificElasticity :(price_id) => fetchData(`/flow/metrics/get/specific/elasticity/?id=${price_id}`),
  fetchPatron :(patron_id) => fetchData(`/flow/metrics/get/patrons/?id=${patron_id}`),
  fetchElasticity :(price_id) => fetchData(`flow/metrics/get/elasticity/?id=${price_id}`)
};
