import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import TabbedContentComponent from "components/tabs";
import { useNavigate } from "react-router-dom";
import Loader from "components/loader/loader";
import { convertTimeZone, convertUTCtoLocal, formatDatetime, localTime} from "helper/dateFormatter";
import QRLinkModal from "components/workshopModal";
import { useCommonToast } from "components/toast/toast";
import { matchKeyValueInList } from "helper/keyValueChecker";
import { createInstantworkShop, getAllWorkshop, updateWorkshop } from "modules/workshop";
import { getAllworkshopsuccessResponse, getAllworkshopfailResponse, createWorkshopsucessResponse } from "modules/types/workshops.model";

export default function WorkshopListing() {
  const navigate = useNavigate();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const [acronym, setAcronym] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [url, setUrl] = useState("");
  const showToast = useCommonToast();
  const [workshops, setWorkshops] = useState({
    activeworkshop: [],
    inactiveworkshop: [],
    historicalworkshop: [],
  });
  const baseUrl = window.location.href.split(window.location.pathname)[0];
  const normalLink = `${baseUrl}/play/${acronym}`;
  const tabConfigurations = [
    {
      name: "Active",
      type: "component",
      columns: [
        { header: "Name", accessorKey: "name" },
        { header: "Client", accessorKey: "tenant_name" },
        {
          header: "Start Date",
          accessorKey: "start_time",
        },
        {
          header: "End Date",
          accessorKey: "end_time",
        },
      ],
      data: workshops.activeworkshop,
      component: "ActiveWorkshop",
    },
    {
      name: "Upcoming",
      type: "component",
      columns: [
        { header: "Name", accessorKey: "name" },
        { header: "Client", accessorKey: "tenant_name" },
        {
          header: "Start Date",
          accessorKey: "start_time",
          cell: ({ row }) => row?.original?.start_time,
        },
        {
          header: "End Date",
          accessorKey: "end_time",
          cell: ({ row }) => row?.original?.end_time,
        },
      ],
      data: workshops.inactiveworkshop,
      component: "ClientProfile",
    },
    {
      name: "History",
      type: "component",
      columns: [
        { header: "Name", accessorKey: "name" },
        { header: "Client", accessorKey: "tenant_name" },
        {
          header: "Start Date",
          accessorKey: "start_time",
          cell: ({ row }) => row?.original?.start_time,
        },
        {
          header: "End Date",
          accessorKey: "end_time",
          cell: ({ row }) => row?.original?.end_time,
        },
      ],
      data: workshops.historicalworkshop,
      component: "ClientProfile",
    },
  ];

  const categorizeWorkshops = (elements) => {
    const newWorkshops = {
      activeworkshop: [],
      inactiveworkshop: [],
      historicalworkshop: [],
    };
    elements.forEach((element) => {
      const startDate = new Date(element.start_time);
      const endDate = new Date(element.end_time);
      const currentDateTime = new Date();
      
      if (startDate > currentDateTime && element.is_active) {
          element["isEndable"]=true;
          newWorkshops.inactiveworkshop.push(element);
        } else if (endDate > currentDateTime && element.is_active) {
          element["isEndable"]=true;
          newWorkshops.activeworkshop.push(element);
        } else {
          element["isEndable"]=false;
          newWorkshops.historicalworkshop.push(element);
        }
      });
    const result = matchKeyValueInList(
      newWorkshops.activeworkshop,
      "name",
      "Instant Workshop"
    );
    setIsDisabled(result);
    return newWorkshops;
  };

  const convertHexToImage = (hex) => {
    const bytes = [];
    for (let i = 0; i < hex.length; i += 2) {
      bytes.push(parseInt(hex.substr(i, 2), 16));
    }
    const blob = new Blob([new Uint8Array(bytes)], { type: "image/png" });
    const url = URL.createObjectURL(blob);
    return url;
  };

  const createWorkshop = async () => {
    setIsLoading(true);
    let resp;
      let payload =  {
        request_url: baseUrl,
      }
      createInstantworkShop(payload).then((response)=>{
        const instantWorkshopData = response as createWorkshopsucessResponse
        resp = response;
        setAcronym(instantWorkshopData.acronym);
        const url = convertHexToImage(instantWorkshopData.image_binary);
        setUrl(url);
        onModalClose();
        setIsModalOpen(true);
        showToast("Success", instantWorkshopData?.message, "success");
      }).catch((error)=>{
        showToast("Error", error?.response?.data?.message, "error");
        throw error;
      }).finally(()=>{
        setIsLoading(false);
      })
    return resp;
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("/workshop/list");
    getWorkshopListing();
  };

  useEffect(() => {
    getWorkshopListing();
  }, []);

  const getWorkshopListing = async () => {
    getAllWorkshop()
    .then((response: getAllworkshopsuccessResponse | getAllworkshopfailResponse) => {
      if (Array.isArray(response)) {
        const workshopListingData = (response as Array<{ 
          id: number;
          tenant_id: number;
          tenant_name: string;
          name: string;           
          acronym: string;
          is_active: boolean;      
          date: string;           
          start_time: string;
          end_time: string;
          image_binary: string;   
        }>).map((item) => ({
          ...item,
        start_time : formatDatetime(convertTimeZone(item?.start_time)),
        end_time : formatDatetime(convertTimeZone(item?.end_time)),
        }));
    const categorizedWorkshops = categorizeWorkshops(workshopListingData);
    setWorkshops(categorizedWorkshops);
      } 
    })
    .catch((error) => {
      console.error("Error fetching chatbot instruction:", error);
    }).finally(()=>{
      setIsInitialLoading(false);
    });
  };

  const handleCreateItem = () => {
    navigate("/workshop/create");
  };

  const handleViewItem = (item) => {
    navigate(`/workshop/view/${item.id}`);
  };

  const handleEditItem = (item) => {
    navigate(`/workshop/edit/${item.id}`);
  };

  const handleCloseItem = (item) => {
    updateWorkshop
    let payload = {
      is_active: false,
    }
      updateWorkshop(payload,item.id)
      .then(() => {
        showToast("Success", `${item.name} successfully ended`, "success");
        getWorkshopListing();
      })
      .catch((error) => {
        console.error("Error ending workshop:", error);
        showToast("Error", `${item.name} couldn't be ended`, "error");
      });
  };

  const openModalBox = () => {
    onOpen();
  };

  return (
    <>
      {isInitialLoading ? (
        <Loader />
      ) : (
        <Box
          id="addclient-form"
          overflowX="auto"
          borderRadius="20px"
          display="flex"
          flexDirection="column"
          flex={1}
          fontFamily="Poppins"
          mx={{ base: "0vw", md: "18vw" }}
        >
          <TabbedContentComponent
            tabConfigurations={tabConfigurations}
            isViewable={true}
            similarWidth={false}
            actionsPresent={true}
            showTopBar={true}
            createButtonText="Create Workshop"
            onCreateItem={handleCreateItem}
            onViewItem={handleViewItem}
            onEditItem={handleEditItem}
            onCloseItem={handleCloseItem}
            SecondarycreateButtonText={"Instant Workshop"}
            SecondaryButtonDisable={isDisabled}
            onSecondaryAction={openModalBox}
          />
          <QRLinkModal
            isOpen={isModalOpen}
            onClose={() => {
              closeModal();
            }}
            qrLink={url}
            normalLink={normalLink}
            title="Scan or Click"
          />
          <Modal isOpen={isOpen} onClose={onModalClose} isCentered>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Instant Workshop Creation</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                You are about to create a workshop. Do you want to proceed?
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={onModalClose}>
                  Cancel
                </Button>
                <Button
                  onClick={createWorkshop}
                  variant="ghost"
                  isLoading={isLoading}
                >
                  Create
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </>
  );
}
