import type { ColumnDef } from "@tanstack/react-table";
import { useMemo, useState, useEffect } from "react";
import "./leaderboard.css";
import { roundOff } from "config/utils";
import { useLocation } from "react-router-dom";
import { useGameStore } from "../../../store/store";
import { toast } from "react-toastify";
import HistoricalDataComponent from "views/instructor/HistoricalData/historicaldatanew";
import { checkSubResourcePermission } from "helper/checkResorcePermission";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  RiMore2Fill,
  RiHistoryLine,
  RiArrowUpSLine,
  RiArrowDownSLine,
} from "react-icons/ri";

import { Table } from "./table";
import {
  getAdminLeaderboardData,
  getHistorical,
  getInsightData,
  getLeaderboardData,
  getWorkshopOptionsData,
  postresetgamemetrics,
} from "modules/leaderboard";
import {
  GameInsights,
  HistoricalData,
  leaderboardfailResponse,
} from "modules/types/leaderboard.model";
import { getAllTenant } from "modules/client";
import {
  getAllTenantfailResponse,
  getallTenantsuccessResponse,
} from "modules/types/client.model";
import { access } from "fs";
import { getAllworkshopsuccessResponse } from "modules/types/workshops.model";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* @ts-ignore */
export interface LeaderboardData {
  data_dict: {
    total: number;
    team_name: string;
    team_id: number;
    RevenuePeriod1: string;
    RevenuePeriod2: string;
    RevenuePeriod3: string;
    PricePeriod1: string;
    PricePeriod2: string;
    PricePeriod3: string;
    inventory_Period1: string;
    inventory_Period2: string;
    inventory_Period3: string;
    inventory_balance: string;
    insights: string;
    rounds: [];
  }[];
  teamname: string;
  num_teams: number;
}

interface SelectOptions {
  tenant_id: string;
  workshop_id: string;
}

const Leaderboard = () => {
  let { authData, Workshop, PermissionsList } = useGameStore();
  let allColumns;
  const [Loading, setLoading] = useState(false);
  const [SecondaryLoading, setSecondaryLoading] = useState(false);

  const [fetching, setfetching] = useState(false);
  const [viewhistory, setviewhistory] = useState(false);
  const [isBlurActive, setIsBlurActive] = useState(false);
  const location = useLocation();
  const isPlayable = location.pathname.includes("play");

  const [historydata, setHistoryData] = useState<HistoricalData[]>([]);
  const [Leaderboarddata, setData] = useState<LeaderboardData>({
    data_dict: [],
    teamname: "",
    num_teams: 0,
  });
  const [tenantOptions, setTenantOptions] = useState([
    {
      label: "",
      value: 0,
    },
  ]);
  const [workshopOptions, setworkshopOptions] = useState([
    {
      label: "",
      value: 0,
    },
  ]);
  const [selectOptions, setSelectOptions] = useState<SelectOptions>({
    tenant_id: "",
    workshop_id: "",
  });
  const [expandedRows, setExpandedRows] = useState<{ [key: number]: boolean }>(
    {}
  );

  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const getleaderboard = async () => {
    try {
      const permission =
        PermissionsList &&
        checkSubResourcePermission(PermissionsList, "leaderboard", "DELETE") &&
        checkSubResourcePermission(PermissionsList, "client", "LIST");

      setLoading(true);

      const response = await getLeaderboardData(
        isPlayable,
        isPlayable ? Workshop.acronym : null,
        isPlayable ? Workshop.workshop_id : null,
        permission
      );
      const leaderboardData = response as LeaderboardData;
      const updatedData = {
        ...leaderboardData,
        data_dict: leaderboardData.data_dict.map((obj) => {
          const totalRevenue = [
            parseFloat(obj?.RevenuePeriod1) || 0,
            parseFloat(obj?.RevenuePeriod2) || 0,
            parseFloat(obj?.RevenuePeriod3) || 0,
          ].reduce((sum, value) => sum + roundOff(value), 0);

          return {
            ...obj,
            total: totalRevenue,
          };
        }),
      };

      setData(updatedData);
      return updatedData;
    } catch (error) {
      console.error("Error sending data to the server:", error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const getInsights = async (
    multiselect = null,
    tenantId = null,
    workshopId = null,
    acronym = null
  ) => {
    setSecondaryLoading(true);

    try {
      const response = await getInsightData(
        isPlayable,
        tenantId ? tenantId.value : null,
        workshopId ? workshopId.value : null,
        multiselect,
        isPlayable ? acronym : null
      );

      const insights = response as GameInsights;

      setData((prevData) => {
        const updatedDataDict = prevData.data_dict.map((item) => {
          const teamInsights =
            insights.insights?.[item.team_id]?.[item.round] || null;

          const updatedRounds =
            item.rounds?.map((round) => ({
              ...round,
              insights:
                insights.insights?.[item.team_id]?.[round.round] || null,
            })) || [];

          return {
            ...item,
            insights: teamInsights,
            rounds: updatedRounds,
          };
        });

        return {
          ...prevData,
          data_dict: updatedDataDict,
        };
      });
    } catch (error) {
      console.error("Error fetching insights:", error);
    } finally {
      setSecondaryLoading(false);
    }
  };

  const getSelectOptions = async () => {
    getAllTenant()
      .then(
        (
          response: getallTenantsuccessResponse[] | getAllTenantfailResponse
        ) => {
          const clientListingData = response as getallTenantsuccessResponse[];
          setTenantOptions(
            clientListingData.map((element) => ({
              label: element.name,
              value: element.id,
            }))
          );
          setSelectOptions((prev) => ({
            ...prev,
            tenant_id: "",
            workshop_id: "",
          }));
        }
      )
      .catch((error: any) => {
        console.error("Error fetching configuration data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAdminLeaderBoard = async (tenantId, workshopId = null) => {
    try {
      setLoading(true);
      const response = await getAdminLeaderboardData(
        tenantId?tenantId.value:null,
        workshopId?workshopId.value:null,
      );

      const LeaderboardData = response as LeaderboardData;
      let updatedData = LeaderboardData;
      updatedData.data_dict = LeaderboardData?.data_dict.map((obj) => {
        let totalRevenue = 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod1)) || 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod2)) || 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod3)) || 0;
        obj.total = totalRevenue;
        return obj;
      });
      setData(updatedData);
      return updatedData;
    } catch (error) {
      console.error("Error fetching workshop data:", error);
      setData((prev) => ({
        ...prev,
        data_dict: [],
        teamname: "",
        num_teams: 0,
      }));
    } finally {
      setLoading(false);
    }
  };

  const getWorkshopOptions = async (tenantId) => {
    setfetching(true);
      getWorkshopOptionsData(tenantId.value).then((response)=>{
        const workshopListingData = response as getAllworkshopsuccessResponse[];
        setSelectOptions((prev) => ({
          ...prev,
          ["workshop_id"]: "",
        }));
        setworkshopOptions(
          workshopListingData.map((element) => ({
            label: element.name,
            value: element.id,
          }))
        );
      }).catch((error)=>{
        console.error("Error fetching workshop data:", error);
      }).finally(()=>{
        setfetching(false);
      })
  };

  useEffect(() => {
    if (selectOptions.tenant_id) {
      getWorkshopOptions(selectOptions.tenant_id);
      getAdminLeaderBoard(selectOptions.tenant_id).then(() => {
        getInsights(false, selectOptions.tenant_id, null);
      });
    }
  }, [selectOptions.tenant_id]);

  useEffect(() => {
    if (selectOptions.workshop_id && selectOptions.tenant_id) {
      getAdminLeaderBoard(
        selectOptions.tenant_id,
        selectOptions.workshop_id
      ).then(() => {
        getInsights(true, selectOptions.tenant_id, selectOptions.workshop_id);
      });
    }
  }, [selectOptions.workshop_id]);

  useEffect(() => {
    if (
      PermissionsList &&
      checkSubResourcePermission(PermissionsList, "leaderboard", "DELETE") &&
      checkSubResourcePermission(PermissionsList, "client", "LIST") &&
      !isPlayable
    ) {
      getSelectOptions();
    }
    getleaderboard().then((resp) => {
      if (
        PermissionsList &&
        checkSubResourcePermission(PermissionsList, "leaderboard", "DELETE") &&
        checkSubResourcePermission(PermissionsList, "client", "LIST")
      ) {
      } else {
        getInsights(null, null, null, isPlayable ? Workshop.acronym : null);
      }
    });
  }, []);

  const addPrefixAndRoundOff = (value: any) => {
    if (value === 0 || value === null) return;
    return `$${roundOff(value)}`;
  };

  const cols = useMemo<ColumnDef<LeaderboardData["data_dict"][0]>[]>(
    () => [
      {
        header: "Team Name",
        cell: (row) => row.renderValue(),
        accessorKey: "team_name",
      },
      {
        header: "Period 1 Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "RevenuePeriod1",
      },
      {
        header: "Period 2 Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "RevenuePeriod2",
      },
      {
        header: "Period 3 Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "RevenuePeriod3",
      },
      {
        header: "Total Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "total",
      },
      {
        header: "Insights",
        cell: (row: any) => {
          const value = row.renderValue();
          const rowIndex = row.row.index;
          const isExpanded = expandedRows[rowIndex];
          const displayText =
            value === null || value === {}
              ? ""
              : isExpanded
              ? value
              : `${value?.slice(0, 100)}...`;

          // return (
          //   <div>
          //     <span title={value}>
          //       {displayText}
          //     </span>
          //   </div>
          // );
          return (
            <div>
              <span>{displayText}</span>
              {value?.length > 100 && (
                <button
                  onClick={() => toggleRowExpansion(rowIndex)}
                  className="ml-1 inline text-blue-500 underline"
                >
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              )}
            </div>
          );
        },
        accessorKey: "insights",
      },
    ],
    [expandedRows, Leaderboarddata]
  );
  if (
    PermissionsList &&
    checkSubResourcePermission(PermissionsList, "leaderboard", "DELETE") &&
    !isPlayable
  ) {
    const buttonColumns: ColumnDef<LeaderboardData["data_dict"][0]>[] = [
      {
        id: "actions",
        cell: (info) => (
          <Menu autoSelect={false}>
            <MenuButton
              as={IconButton}
              icon={<RiMore2Fill />}
              variant="ghost"
              _hover={{}}
              size="md"
            />
            <MenuList w={150} minW={0}>
              {/* {onViewItem && (
                <MenuItem
                  _hover={{ bg: "HeaderFillbg", color: "white" }}
                  onClick={() => onViewItem(info.row.original)}
                >
                  <RiEyeLine className="mr-2 h-6 w-6" /> View
                </MenuItem>
              )} */}
              <MenuItem
                _hover={{ bg: "HeaderFillbg", color: "white" }}
                onClick={() => getHistoricalData(info.row.original)}
                w={148}
              >
                <RiHistoryLine className="mr-2 h-5 w-5" />
                Historical
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
      {
        id: "toggle",
        cell: (info) => (
          <div>
            {info.cell.row.original?.rounds?.length ? (
              <IconButton
                aria-label="dropdown"
                icon={
                  info.row.getIsExpanded() ? (
                    <RiArrowUpSLine />
                  ) : (
                    <RiArrowDownSLine />
                  )
                }
                variant="ghost"
                _hover={{}}
                size="xl"
                onClick={info.row.getToggleExpandedHandler()}
              />
            ) : null}
          </div>
        ),
      },
    ];

    allColumns = [...buttonColumns, ...cols];
  } else {
    allColumns = [...cols];
  }
  const getReset = async (rowData) => {
    setLoading(true);
    postresetgamemetrics(rowData)
      .then((response) => {
        setLoading(false);
        toast.success(
          <>
            <div className="font-Helvetica text-lg font-bold tracking-wide">
              Reset Successful
            </div>
            <div className="font-Helvetica text-sm tracking-wide">
              Team reset Successful
            </div>
          </>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              setIsBlurActive(false);
            },
          }
        );
        getleaderboard();
        return response;
      })
      .catch(() => {
        setLoading(false);
        if (rowData.team_name !== "") {
          setIsBlurActive(true);
          toast.error(
            <>
              <div className="font-Helvetica text-lg font-bold tracking-wide">
                Data Not Found
              </div>
              <div className="font-Helvetica text-sm tracking-wide">
                Team Reset unsuccessful
              </div>
            </>,
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              onClose: () => {
                setIsBlurActive(false);
              },
            }
          );
        }
      });
  };
  const getHistoricalData = async (
    rowData: LeaderboardData["data_dict"][0]
  ) => {
    try {
      setLoading(true);
      const isAdmin = PermissionsList && PermissionsList.name === "Admin";
      let payload =  {
        team_id: rowData.team_id,
      }
      const response  = await getHistorical(payload) as HistoricalData[]
      setLoading(false);
      setHistoryData(response);
      setviewhistory(true);
      return response;
    } catch (error) {
      setLoading(false);
      if (rowData.team_name !== "") {
        setIsBlurActive(true);
        toast.error(
          <>
            <div className="font-Helvetica text-lg font-bold tracking-wide">
              Data Not Found
            </div>
            <div className="font-Helvetica text-sm tracking-wide">
              Historical data for "{rowData.team_name}" does not exist
            </div>
          </>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              setIsBlurActive(false);
            },
          }
        );
      }
      console.error("Error sending data to the server:", error);
    }
  };
  const handleBackClick = () => {
    setviewhistory(false);
    setHistoryData([]);
  };

  const handleGameChange = (selectedOption, fieldId) => {
    setSelectOptions((prev) => ({
      ...prev,
      [fieldId]: selectedOption,
    }));
  };
  return (
    <>
      <div className="mt-20 lg-max:hidden">
        {Loading ? (
          <div className="flex items-center justify-center">
            <div className="h-10 w-10 animate-spin rounded-full border-t-2 border-blue-600"></div>
          </div>
        ) : !viewhistory ? (
          <Table
            key={Leaderboarddata.data_dict.length}
            data={Leaderboarddata.data_dict}
            tenantOption={tenantOptions}
            columns={allColumns}
            teamname={Leaderboarddata.teamname}
            numberofTeams={Leaderboarddata.num_teams}
            BlurActive={isBlurActive}
            selectOptions={selectOptions}
            handleGameChange={handleGameChange}
            setselectOptions={setSelectOptions}
            workshopOptions={workshopOptions}
            isLoading={Loading}
            isFetching={fetching}
            getAdminLeaderBoard={getAdminLeaderBoard}
            getReset={getReset}
            isSecondaryLoading={SecondaryLoading}
          />
        ) : viewhistory ? (
          <>
            <HistoricalDataComponent
              historicaldata={historydata}
              handleBackClick={handleBackClick}
            ></HistoricalDataComponent>
          </>
        ) : null}
      </div>
      <div className="flex h-screen items-center justify-center text-center text-3xl font-semibold text-white md-max:visible lg-min:hidden">
        Please Rotate Your Screen
      </div>
    </>
  );
};
export default Leaderboard;
