import { auth } from "../service/auth";
import { toast } from "react-toastify";
// import { userformData } from "DataTypes";
import {
  userformData,
  loginsuccessResponse,
  loginfailResponse,
  PermissionsuccessResponse,
  PermissionfailResponse,
  refreshPayload,
  tenantConfigSuccessResponse,
  tenantConfigFailResponse,
  idpLoginPayload,
} from "./types/auth.model";
import { WorkshopData } from "store/workshop/workShopSlice";

export const handleLogin = async (
  userformData: userformData
): Promise<loginsuccessResponse | loginfailResponse> => {
  try {
    const response = await auth.login(userformData);

    const data: loginsuccessResponse = response.data;

    if (response.status === 200) {
      return data;
    }
  } catch (loginfailResponse) {
    throw loginfailResponse
  }
};

export const handleWorkshopLogin = async (
  userformData,gamecode
): Promise<WorkshopData | loginfailResponse> => {
  try {
    const response = await auth.loginWorkshop(userformData,gamecode);

    const data: WorkshopData = response.data;

    if (response.status === 200) {
      return data;
    }
  } catch (loginfailResponse) {
    throw loginfailResponse
  }
};

export const handlePermission = async (): Promise<
  PermissionsuccessResponse | PermissionfailResponse
> => {
  try {
    const response = await auth.permissions();
    const data: PermissionsuccessResponse = response.data;
    if (response.status === 200) {
      return data;
    }
  } catch (PermissionfailResponse) {
    throw PermissionfailResponse

  }
};

export const handleLogouted = async (
  refreshPayload
): Promise<any> => {
  try {
    const response = await auth.logout(refreshPayload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const handleLogoutworkshop = async (
  refreshPayload,acronym
): Promise<any> => {
  try {
    const response = await auth.logoutworkshop(refreshPayload,acronym);
    return response;
  } catch (error) {
    throw error;
  }
};

export const handleGetConfig = async (
  tenant_name: string
): Promise<tenantConfigSuccessResponse | tenantConfigFailResponse> => {
  try {
    const response = await auth.getconfig(tenant_name);

    const data: tenantConfigSuccessResponse = response.data;

    if (response.status === 200) {
      return data;
    }
  } catch (tenantConfigFailResponse) {
    throw tenantConfigFailResponse
  }
};

export const handleIdpLogin = async (
  idpPayload: idpLoginPayload,headers
): Promise<loginsuccessResponse | loginfailResponse> => {
  try {
    const response = await auth.idpLogin(idpPayload,headers);

    const data: loginsuccessResponse = response.data.data;

    if (response.status === 200 || 201) {
      return data;
    }
  } catch (tenantConfigFailResponse) {
    throw tenantConfigFailResponse
  }
};

