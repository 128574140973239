

import {chatbot} from "../service/chatbot"
import {chatbotInstructionsuccessResponse,chatbotInstructionfailResponse,pathnamePayload ,chatbotResponsesuccessResponse,chatbotResponsefailResponse,responsePayload} from "./types/chatbot.model"


export const chatbotInstruction = async (pathnamePayload: pathnamePayload,isPlayable,acronym=null): Promise<chatbotInstructionsuccessResponse|chatbotInstructionfailResponse> => {
    try {
      let response;
      if(isPlayable){
      response = await chatbot.chatbotInstructionWorkshop(pathnamePayload,acronym);
      }else{
        response = await chatbot.chatbotInstruction(pathnamePayload);
      }

      const data: chatbotInstructionsuccessResponse = response.data;

     if (response.status === 200) {
        return data;
        }  
    } catch (chatbotInstructionfailResponse) {
      throw chatbotInstructionfailResponse

    }
  };


  export const chatbotResponse = async (Payload: responsePayload,isPlayable,acronym=null): Promise<chatbotResponsesuccessResponse|chatbotResponsefailResponse> => {
    try {

      let response;
      if(isPlayable){
      response = await chatbot.chatbotResponseWorkshop(Payload,acronym);
      }else{
        response = await chatbot.chatbotResponse(Payload);
      }

      const data: chatbotResponsesuccessResponse = response.data;

      if (response.status === 200) {
        return data;
        }  
     
    } catch (chatbotResponsefailResponse) {
      throw chatbotResponsefailResponse
    }
  };  


