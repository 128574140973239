import { configuration } from "../service/configuration";
import {
  getAllpatronsucessrespone,
  getAllpatronfailResponse,
  getAllelasticitysucessrespone,
  getAllelasticityfailResponse,
  configurationPayload,
  configurationSuccessresponse,
  configurationFailresponse,
  timePatronpayload,
  timePatronSuccessresponse,
  timePatronFailresponse,
  queryParam,
  getByidElasticitysuccessresponse,
  getByidElasticityfailResponse,
  getByidTimepatronsuccessresponse,
  getByidTimepatronfailResponse,
  putConfigurationpayload,
  putPatronpayload,
  putConfigurationsuccessresponse,
  putConfigurationFailresponse,
  putTimePatronsuccessresponse,
  putTimePatronFailresponse,
  createConfigurationPayload

} from "./types/configuration.model";

export const getAllpatron = async (): Promise<
  getAllpatronsucessrespone [] | getAllpatronfailResponse
> => {
  try {
    const response = await configuration.getPatrons();
    const data: getAllpatronsucessrespone[] = response.data;
    if (response.status === 200) {
      return data;
    }
  } catch (getAllpatronfailResponse) {
    throw getAllpatronfailResponse
  }
};

export const getAllelasticity = async (): Promise<
  getAllelasticitysucessrespone[] | getAllelasticityfailResponse
> => {
  try {
    const response = await configuration.getElasticity();
    const data: getAllpatronsucessrespone[] = response.data;
    if (response.status === 200) {
      return data;
    }
  } catch (getAllpatronfailResponse) {
    throw getAllpatronfailResponse
  }
};




export const createElasticity = async (configurationPayload: configurationPayload,): Promise<configurationSuccessresponse|configurationFailresponse> => {
    try {
      const response = await configuration.createElasticity(configurationPayload);

      const data: configurationSuccessresponse = response.data;

     if (response.status === 201) {
        return data;
        }  
    } catch (configurationFailresponse) {
      throw configurationFailresponse

    }
  };

  export const createConfiguration = async (configurationPayload): Promise<configurationSuccessresponse|configurationFailresponse> => {
    try {
      const response = await configuration.createConfiguration(configurationPayload);

      const data: configurationSuccessresponse = response.data;

     if (response.status === 201) {
        return data;
        }  
    } catch (configurationFailresponse) {
      throw configurationFailresponse 

    }
  };

  export const updateConfiguration = async (configurationPayload,id:string): Promise<configurationSuccessresponse|configurationFailresponse> => {
    try {
      const response = await configuration.updateConfiguration(configurationPayload,id);

      const data: configurationSuccessresponse = response.data;

     if (response.status === 200) {
        return data;
        }  
    } catch (configurationFailresponse) {
          
      throw configurationFailresponse

    }
  };



  export const createTimepatron = async (timePatronpayload: timePatronpayload,): Promise<timePatronSuccessresponse|timePatronFailresponse> => {
    try {
      const response = await configuration.createTimepatrons(timePatronpayload);

      const data: timePatronSuccessresponse = response.data;
     if (response.status === 201) {
        return data;
        }  
    } catch (timePatronFailresponse) {
      throw timePatronFailresponse

    }
  };




  export const getByidElasticity = async (queryParams): Promise<getByidElasticitysuccessresponse|getByidElasticityfailResponse> => {
    try {
      const response = await configuration.getByidElasticity(queryParams);

      const data: getByidElasticitysuccessresponse = response.data;

     if (response.status === 200) {
        return data;
        }  
    } catch (getByidElasticityfailResponse) {
      throw getByidElasticityfailResponse;

    }
  };




  export const getByidTimepatron = async (queryParams): Promise<getByidTimepatronsuccessresponse|getByidTimepatronfailResponse> => {
    try {
      const response = await configuration.getByidTimepatron(queryParams);

      const data: getByidTimepatronsuccessresponse = response.data;

     if (response.status === 200) {
        return data;
        }  
    } catch (getByidTimepatronfailResponse) {
      throw getByidTimepatronfailResponse

    }
  };




  export const putElasticity = async (queryParams,putConfigurationpayload): Promise<putConfigurationsuccessresponse|putConfigurationFailresponse> => {
    try {
      const response = await configuration.putElasticity(queryParams,putConfigurationpayload);

      const data: putConfigurationsuccessresponse = response.data;

     if (response.status === 200) {
        return data;
        }  
    } catch (putConfigurationFailresponse) {
      throw putConfigurationFailresponse

    }
  };


  export const putTimePatron = async (queryParams,putPatronpayload): Promise<putTimePatronsuccessresponse|putTimePatronFailresponse> => {
    try {
      const response = await configuration.putTimepatron(putPatronpayload,queryParams);

      const data: putTimePatronsuccessresponse = response.data;

     if (response.status === 200) {
        return data;
        }  
    } catch (putTimePatronFailresponse) {
      throw putTimePatronFailresponse

    }
  };






