import axiosClient from "config/apiClient";
import { getgame3 } from "modules/types/game3.model";
import { authData } from "store/auth/authSlice";
import { WorkshopData } from "store/workshop/workShopSlice";

export const game3 = {
    gettime: () => axiosClient.get("/workshop/patron/"),
    gettimeWorkshop: (Workshop:WorkshopData) => axiosClient.get(`/workshop/patron/?acronym=${Workshop.acronym}&id=${Workshop.configs.patron_id}`),
    getgame3 : (getgame3) => axiosClient.post("workshop/initial/game/metrics/",getgame3),
    getgame3Workshop : (Workshop:WorkshopData, getgame3) => axiosClient.post(`workshop/initial/game/metrics/?acronym=${Workshop.acronym}&id=${Workshop.configs.price_elasticity_id}`,getgame3),
    getAllPeRecords : () => axiosClient.get("/workshop/elasticity/"),
    getAllPeRecordsWorkshop : (Workshop:WorkshopData) => axiosClient.get(`/workshop/elasticity/?acronym=${Workshop.acronym}&id=${Workshop.configs.price_elasticity_id}`),
    postgame3: (game3Payload:game3Payload) => axiosClient.post("/workshop/play/game/",game3Payload),
    postgame3Workshop: (game3Payload:game3Payload,acronym) => axiosClient.post(`/workshop/play/game/?acronym=${acronym}`,game3Payload)
};