import React, { useCallback, useEffect, useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import Profile from "components/profiler/profiler";
import GenericDataTable from "components/table/ListingTable";
import { useLocation, useParams } from "react-router-dom";
import Loader from "components/loader/loader";
import { fetchData } from "helper/fetchData";
import { configuration } from "service/configuration";

interface profiles {
  Name: string,
  Game: string,
  File_Type:string,
  File_Name: string,
}

interface ConfigurationType {
  priceElasticity: any[];
  patron: any[];
  profile :profiles[];
}

const CreateUserForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [configurationDetail, setConfigurationDetail] = useState<ConfigurationType | null>(null);
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { type } = location.state as { type: string } || {};

  const configurations = {
    "Price Elasticity": {
      name: "Price Elasticity",
      type: "table",
      columns: [
        { header: "Price", accessorKey: "Price" },
        { header: "Demand", accessorKey: "Demands" },
        { header: "Time", accessorKey: "Time" },
        { header: "Period", accessorKey: "Period" },
      ],
      data: configurationDetail?.priceElasticity,
    },
    "Time to Patron": {
      name: "Time to Patron",
      type: "table",
      columns: [
        { header: "Time", accessorKey: "time" },
        { header: "Patrons", accessorKey: "patrons" },
        { header: "Period", accessorKey: "period" },
      ],
      data: configurationDetail?.patron,
    },
  };

  const getConfigurationListing = useCallback(async () => {
    try {
      const configData = await (type === "Time to Patron"? configuration.fetchSpecificPatron(id) : configuration.fetchSpecificElasticity(id))
      const data = await (type === "Time to Patron"? configuration.fetchPatron(id) : configuration.fetchElasticity(id))
      const profiler = {
        Name:data[0].name,
        Game:"Game 3",
        File_Type:type,
        File_Name: data[0].uploaded_file_name,
      }
      setConfigurationDetail(prev => ({
        ...prev,
        priceElasticity: configData?.elasticity || [],
        patron: configData?.patron || [],
        profile : [profiler] || [],
      }));
    } catch (error) {
      console.error("Error fetching configuration data:", error);
    }
  }, [fetchData, type, id]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setIsLoading(true);
      await getConfigurationListing();
      setIsLoading(false);
    };

    fetchUserDetails();
  }, [getConfigurationListing]);
  
  if (isLoading) return <Loader />;

  return (
    <Box
      id="addtenant-form"
      overflowX="auto"
      borderRadius="20px"
      display="flex"
      flexDirection="column"
      flex={1}
      fontFamily="Poppins"
      mx={{ base: "0vw", md: "18vw" }}
    >
      <Flex direction="column" flex={1}>
        <Flex
          bg="TableHeaderbg"
          justify="space-between"
          align="center"
          py="10px"
          px="20px"
          textColor="#0B389E"
          fontWeight={600}
          fontSize="16px"
        >
          View Configuration
        </Flex>
        <Flex
          bg="Containerbg"
          flex={1}
          py="10px"
          px="20px"
          direction="column"
          justifyContent="space-between"
        >
          <Profile
            Details={configurationDetail?.profile[0]}
            link="/configuration/edit/"
            header={configurationDetail?.profile[0].File_Type}
            linkdata={{ type: configurationDetail?.profile[0].File_Type}}
          />
          {configurations[type] && (
            <GenericDataTable
              data={configurations[type].data}
              columns={configurations[type].columns}
              isViewable={true}
              similarWidth={true}
              actionsPresent={false}
            />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default CreateUserForm;