import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  VStack,
  InputGroup,
  InputRightElement,
  Input,
} from "@chakra-ui/react";
import { IoSendSharp } from "react-icons/io5";
import {
  chatbotInstruction,
  chatbotResponse as chatbotResponseSender,
} from "modules/chatbot"; 

export const Typewriter = ({ text, delay, setIsSending, renderFunc }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);
      return () => clearTimeout(timeout);
    } else {
      setIsSending(false);
    }
  }, [currentIndex, delay, text, setIsSending]);

  useEffect(() => {
    const chatbox = document.getElementById("chatbot-messages");
    if (chatbox) {
      chatbox.scrollTop = chatbox.scrollHeight;
    }
  }, [currentText]);

  return <span>{renderFunc ? renderFunc(currentText) : currentText}</span>;
};

export const scrollToBottom = (ref) => {
  ref.current?.scrollIntoView({ behavior: "smooth" });
};

export const fetchChatbotInstruction = async (
  setInitialPrompts,
  setLoading,
  setMessages,
  isPlayable,
  acronym
) => {
  try {
    let pathnamePayload = {
      pathname: window.location.pathname.split("/").pop(),
    };
    chatbotInstruction(pathnamePayload, isPlayable, acronym)
      .then((resp) => {
        if ("defaultprompt" in resp) {
          setInitialPrompts(resp.defaultprompt);
          setMessages((prevMessages) => [
            ...prevMessages,
          ]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching chatbot instruction:", error);
      });
  } catch (error) {
    console.error("Error fetching chatbot instruction:", error);
    setLoading(false);
  }
};

export const handleSendMessage = async (
  message,
  messages,
  setMessages,
  setUserMessage,
  setIsSending,
  setShowInitialPrompts,
  isPlayable,
  acronym,
  userName
) => {
  if (message.trim() === "") return;
  setUserMessage("");
  setIsSending(true);
  setMessages((prevData) => [...prevData, { content: message, role: "user" }]);
  setShowInitialPrompts(false);
  setMessages((prevMessages) => [
    ...prevMessages,
    {
      content: "Generating Response",
      role: "animations",
    },
  ]);

  let responsePayload = {
    prompt: [...messages, { content: message, role: "user" }],
    request_from: window.location.pathname.split("/").pop(),
    user_name: userName ? userName : null,
  };

  chatbotResponseSender(responsePayload, isPlayable, acronym)
    .then((response) => {
      if ("response" in response) {
        const chatbotResponse = response.response;
        if (chatbotResponse) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { content: chatbotResponse, role: "assistant" },
          ]);
        } else {
          throw new Error("Empty response from the chatbot.");
        }
      }
    })
    .catch((error) => {
      console.error("Error generating chat:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          content: "Error generating response. Please try again later.",
          role: "assistant",
        },
      ]);
    })
    .finally(() => {
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.role !== "animations")
      );
      setIsSending(false);
      setUserMessage("");
    });
};

export const renderMessage = (message, index, Typewriter, setIsSending) => {
  if (message.role === "system") return null;

  const renderBoldText = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });
  };

  return (
    <Box
      key={index}
      alignSelf={message.role === "user" ? "flex-end" : "flex-start"}
      bg={message.role === "user" ? "#0C39A0" : "white"}
      color={message.role === "user" ? "white" : "black"}
      borderRadius={
        message.role === "user" ? "10px 10px 0px 10px" : "0px 10px 10px 10px"
      }
      p={3}
      maxW="80%"
      shadow={"xl"}
    >
      {message.role === "assistant" ? (
        <Typewriter
          text={message.content}
          delay={15}
          setIsSending={setIsSending}
          renderFunc={renderBoldText}
        />
      ) : message.role === "animations" ? (
        <div className="flex flex-row gap-2">
          <div className="bouncing-ball1"></div>
          <div className="bouncing-ball2"></div>
          <div className="bouncing-ball3"></div>
        </div>
      ) : (
        renderBoldText(message.content)
      )}
    </Box>
  );
};

export const RenderInitialPrompts = ({ initialPrompts, handlePromptClick }) => {
  return (
    <VStack align="stretch" spacing={2} className="max-h-[35vh] overflow-auto">
      {initialPrompts.map((prompt, index) => (
        <Button
          key={index}
          onClick={() => handlePromptClick(prompt)}
          bgColor="transparent"
          variant="solid"
          fontSize={"13px"}
          width={"100%"}
          whiteSpace="normal"
          height="auto"
          padding="3"
          textAlign="center"
          className="break-words"
          textColor={"black"}
          shadow={"lg"}
          opacity={"100%"}
          fontWeight={500}
          borderRadius={"10px"}
          fontFamily={"poppins"}
          color={"#0D3AA1"}
          border={"1px"}
          borderColor={"#385DAE"}
          wordBreak={"break-word"}
        >
          {prompt}
        </Button>
      ))}
    </VStack>
  );
};

export const renderChatInput = (
  userMessage,
  setUserMessage,
  isSending,
  handleSendMessage
) => (
  <Flex
    mt={2}
    alignContent={"center"}
    alignItems={"center"}
    transition="all 0.3s ease-in-out"
  >
    <InputGroup alignContent={"center"} alignItems={"center"}>
      <Input
        value={userMessage}
        onChange={(e) => setUserMessage(e.target.value)}
        placeholder="Ask a question..."
        _placeholder={{
          alignContent: "center",
          alignItems: "center",
          mt: "50%",
        }}
        bgColor={"white"}
        pr={16}
        h={16}
        disabled={isSending}
        resize={"none"}
        borderRadius={"10px"}
        borderBottomRightRadius={"1px"}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
          }
        }}
        className="scroll-smooth"
      />
      <InputRightElement alignContent={"center"} alignItems={"center"}>
        <IconButton
          isRound={true}
          variant="solid"
          bgGradient="linear(to-r, #C6C1E7,#0A379F)"
          aria-label="Done"
          fontSize="20px"
          icon={<IoSendSharp />}
          alignContent={"center"}
          alignItems={"center"}
          textColor={"white"}
          onClick={() => handleSendMessage()}
          isLoading={isSending}
          _hover={{ boxShadow: "2xl" }}
          _focus={{ bgGradient: "linear(to-l, #C6C1E7,#0A379F)" }}
          mt={"50%"}
          mr={5}
        />
      </InputRightElement>
    </InputGroup>
  </Flex>
);
