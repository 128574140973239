import axiosClient from "config/apiClient";

import {option,profitCalculationData} from "../modules/types/game2.model"


export const game2 = {
    probabilitydistribution: (option:option) => axiosClient.post("game2/probabilitydistribution",option),
    probabilitydistributionworkshop: (option:option,acronym) => axiosClient.post(`workshop/game2/probability/?acronym=${acronym}`,option),
    profitcalculation : (profitCalculationData:profitCalculationData) => axiosClient.post("game2/profitcalculation",profitCalculationData),
    profitcalculationworkshop : (profitCalculationData:profitCalculationData,acronym) => axiosClient.post(`workshop/game2/profit/?acronym=${acronym}`,profitCalculationData)
};
