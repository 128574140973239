import axiosClient from "config/apiClient";
import { userformData, refreshPayload , idpLoginPayload } from "../modules/types/auth.model"


export const auth = {
  login: (userformData:userformData) => axiosClient.post("tenant/authenticate/login/", userformData),
  loginWorkshop: (userformData,gameCode) => axiosClient.post(`/workshop/create/team/?acronym=${gameCode}`, userformData),
  logout: (refreshPayload:refreshPayload) => axiosClient.post("/tenant/logout/", refreshPayload),
  logoutworkshop: (refreshPayload:refreshPayload,acronym) => axiosClient.post(`workshop/logout/?acronym=${acronym}`, refreshPayload),
  refresh: () => axiosClient.post("/tenant/login/refresh/"),
  permissions: () => axiosClient.get("rbac/user/permissions/"),
  getconfig: (tenant_name:string)=> axiosClient.get(`idp/get/config/?name=${tenant_name}`),
  idpLogin: (idpLoginPayload:idpLoginPayload,headers)=> axiosClient.post(`idp/user/login/`,idpLoginPayload,{headers})
};
