import axiosClient from "config/apiClient";

export const leaderboard = {
    getleaderboard: () => axiosClient.get(`/workshop/leaderboard/`),
    getleaderboardworkshop: (acronym,workshop_id) => axiosClient.get(`/workshop/leaderboard/?acronym=${acronym}&workshop=${workshop_id}`),
    gettenantOptions : () => axiosClient.get("/tenant/get/tenants/"),
    getadminLeaderboardTenant : (tenantid) => axiosClient.get(`workshop/leaderboard/overall/?tenant_id=${tenantid}`),
    getadminLeaderboardTenantWorkshop : (tenantid,workshopid) => axiosClient.get(`workshop/leaderboard/overall/?tenant_id=${tenantid}&workshop_id=${workshopid}`),
    getWorkshopOptions: (tenantid) => axiosClient.get(`/workshop/get/?tenant_id=${tenantid}`),
    postReset: (resetData) => axiosClient.post("game3/getreset",{"team_ids":resetData}),
    postHistorical: (historicalData) => axiosClient.post("workshop/historical/",{"team_id":historicalData}),
    getInsightTenant : (tenantid) => axiosClient.get(`/workshop/insight/?tenant_id=${tenantid}`),
    getInsightTenantWorkshop : (tenantid,workshopid) => axiosClient.get(`/workshop/insight/?tenant_id=${tenantid}&workshop_id=${workshopid}`),
    getInsightWorkshop : (acronym,workshop_id) => axiosClient.get(`workshop/insight/?acronym=${acronym}&workshop=${workshop_id}`),
    getInsight : () => axiosClient.get(`/workshop/insight/`),
    getHistorical : (payload) => axiosClient.post(`workshop/historical/`,payload),
};