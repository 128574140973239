import { InitialData } from "store/game1A/game1ASlice";
import {getInitialpatron} from "../service/game1"
import {getInitialpatronssuccessResponse,getInitialpatronsfailResponse, getCalculationsuccessResponse} from "./types/game1.model"


export const getInitialpatrons = async (isPlayable,acronym): Promise<Array<InitialData>|getInitialpatronsfailResponse> => {
    try {
      let response;
      if(isPlayable){
        response = await getInitialpatron.getInitialpatronWorkshop(acronym)
      }else{
        response = await getInitialpatron.getInitialpatron()
      }

      const data: Array<InitialData> = response.data.initial_patron;

         if (response.status === 200) {
            return data;
            } 
    } catch (getInitialpatronsfailResponse) {
      throw getInitialpatronsfailResponse
    }
  };

  export const postcalculation = async (isPlayable,acronym,feats): Promise<getCalculationsuccessResponse | getInitialpatronsfailResponse> => {
    try {
      let response;
      if(isPlayable){
        response = await getInitialpatron.postcalculationWorkshop(acronym,feats)
      }else{
        response = await getInitialpatron.postcalculation(feats)
      }

      const data: getCalculationsuccessResponse = response.data;

         if (response.status === 200) {
            return data;
            } 
    } catch (getInitialpatronsfailResponse) {
      throw getInitialpatronsfailResponse
    }
  };

