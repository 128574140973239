import { Image, Box, Flex } from "@chakra-ui/react";
import aboutimage from "../../assets/about/bar_frame1.jpg";
import AboutBox from "./components";
import "./chevron.css";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* @ts-ignore */

const About = () => {

  return (
    <Flex
      mt={28}
      alignItems="center"
      justifyContent="space-between"
      flexDirection={{ base: "column", lg: "row" }}
    >
        <AboutBox />
      <Box
        width={{ base: "100%", lg: "50%" }}
        borderWidth={1}
        borderStyle="solid"
        borderColor="custom.borderColor"
      >
        <Image src={aboutimage} alt="About Image" objectFit="cover" />
      </Box>
    </Flex>
  );
};
export default About;
