import { useState } from "react";
// import Game2Header from "./components/Game1BHeader";
import Game2Variables from "./components/Game1BVariables.json";
import SelectIcon from "../../assets/game1/selectIcon.svg";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useGameStore } from "../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";
import "../game1/color1B.css";
import { BiSolidInfoSquare } from "react-icons/bi";
import { Tooltip } from "react-tooltip";
import "../game2/components/radiodistribution/index.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/modal";
import { Button } from "@chakra-ui/button";
import { useDisclosure } from "@chakra-ui/hooks";
import ScatterPlot from "components/chart/scatterPlot";
import { Link } from "@chakra-ui/react";
import { postcalculation } from "modules/game1";
import { getCalculationsuccessResponse, getInitialpatronsfailResponse } from "modules/types/game1.model";


interface ButtonData {
  text: string;
  color: string;
  textColor: string;
  borderColor: string;
  key: string;
}

interface ChartData {
  predictions: number[];
  observedValues: number[];
  highestPrediction:number[];
}

export default function Game1B() {
  const navigate = useNavigate();
  const [loading, Setloading] = useState(false);
  const variables: { [key: string]: ButtonData } = Game2Variables;
  const solution = `[0.5 * ${Game2Variables["v2"].text} * (${Game2Variables["v4"].text}} / ${Game2Variables["v3"].text}})] * [1 + 0.5 * ${Game2Variables["v6"].text}} - 0.4 * ${Game2Variables["v8"].text}}] + 3 * ln(${Game2Variables["v5"].text}}) + 4`;

  const [selectedVariables, setSelectedVariables] = useState<ButtonData[]>([]);
  const [chartData, setChartData] = useState<ChartData>();
  const [generateCounter, setGenerateCounter] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAIModalOpen, setIsAIModalOpen] = useState(false);
  const [isAIGenerated, setIsAIGenerated] = useState(false);
  const [isGameCompletedModalOpen, setIsGameCompletedModalOpen] =
    useState(false);
  const [beerSalesEquation, setBeerSalesEquation] = useState("");
  //const [AutobeerSalesEquation, setAutoBeerSalesEquation] = useState("");
  const [accuracy, setAccuracy] = useState(0);
  const [showHelp, setShowHelp] = useState(true);

  const location = useLocation();
  const isPlayable = location.pathname.includes("play");
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const { setIsGame1BCompleted, Workshop } = useGameStore();
  const handleVariableClick = (variable: ButtonData) => {
    if (!selectedVariables.includes(variable)) {
      setSelectedVariables([...selectedVariables, variable]);
    }
  };

  const handleRemoveClick = (variable: ButtonData) => {
    setSelectedVariables(selectedVariables.filter((item) => item !== variable));
  };

  async function getEquation() {
    let feats = selectedVariables.map((item) => item.key);
    Setloading(true);
    postcalculation(isPlayable,isPlayable?Workshop.acronym:null,feats)
      .then((resp : getCalculationsuccessResponse | getInitialpatronsfailResponse) => {
        const response = resp as getCalculationsuccessResponse
        setGenerateCounter(generateCounter + 1);
        if (generateCounter === 4 && !isAIGenerated) {
          setIsModalOpen(true);
        }
        let generatedFunction = response["P(Beer Sales)"];
        setAccuracy(response.Accuracy);
        setChartData((prev) => ({
          ...prev,
          predictions: response["Predictions"],
          observedValues: response["Observed Values"],
          highestPrediction: response["Highest predictions"],
        }));
        function replaceKeysWithValues(match: string) {
          return Game2Variables[match].text || match;
        }
        const replacedEquation = generatedFunction.replace(
          /v\d+/g,
          replaceKeysWithValues
        );
        Setloading(false);
        setBeerSalesEquation(replacedEquation);
      })
      .catch((error) => {
        Setloading(false);
        toast.error("There was an error connecting to Server", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  }
  return (
    <div className="max-w-screen relative flex h-screen flex-col items-center">
      <div className="mt-1 flex w-full max-w-[80%] flex-col rounded-md border border-white bg-white px-4 py-1">
        {showHelp ? (
          <div className="relative left-[90%] top-[40%] z-50 flex w-[25%] animate-animateFadeIn">
            <div className="help h-38 absolute top-[50%] flex w-72 flex-col items-center justify-between gap-0 rounded-[20px] p-3 ipad11-max:h-40">
              <span className="text-sm">
                Click the variables you think can help predict beer sales for
                any given night into the box below and hit "Generate Function"
                button
              </span>
              <button
                className="button-class rounded-md px-5 py-[2px] text-sm text-white underline-offset-2 hover:bg-[#3072D6]"
                onClick={() => {
                  setShowHelp(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        ) : (
          <button
            className="absolute right-[11%] top-2"
            onClick={() => {
              setShowHelp(true);
            }}
          >
            <InformationCircleIcon className="h-6 w-6 text-blue-900" />
          </button>
        )}
        <h2 className="text-2xl font-semibold  text-[#26337C]">
          Build a function to predict beer sales
        </h2>
        <h6 className="text-lg text-[#26337C]">
          (click the variables that you wish to add)
        </h6>
        <h3 className="mt-2 rounded-lg border border-[#2153C2] bg-[#2153C2]  text-center text-base font-semibold uppercase text-white shadow-sm">
          Input variables
        </h3>

        <div className="mt-0 grid gap-[10px] rounded-lg border border-gray-400 bg-[#F7FAF8] p-3 md:grid-cols-3 md-max:grid-cols-2 lg:grid-cols-6">
          {Object.keys(variables).map((key) => (
            <button
              key={key}
              className={`relative h-20 rounded-[10px] border-2 p-1 text-[1rem] hover:scale-105 md-max:text-[12px]
              ${
                selectedVariables.includes(variables[key])
                  ? "border-gray-500 bg-[#E5E5E5] text-gray-500 hover:bg-gray-300"
                  : `${variables[key].textColor} ${variables[key].borderColor} ${variables[key].color} hover:${variables[key].color}`
              }`}
              onClick={() => {
                if (selectedVariables.includes(variables[key])) {
                  handleRemoveClick(variables[key]);
                } else {
                  handleVariableClick(variables[key]);
                }
              }}
            >
              {variables[key].text}
            </button>
          ))}
        </div>
        <h3 className="mt-4 rounded-lg border border-[#2153C2] bg-[#2153C2] text-center text-base font-semibold uppercase text-white shadow-sm">
          Selected variables
        </h3>
        <div
          className={`flex flex-col
        ${
          Object.keys(selectedVariables).length === 0
            ? "items-center justify-center"
            : null
        }  mt-[0px] h-[9.5rem] w-full rounded-lg border border-dashed border-gray-400 bg-[#EAF4FF]`}
        >
          {Object.keys(selectedVariables).length === 0 ? (
            <div className="flex flex-col items-center">
              <img
                src={SelectIcon}
                className="w-9 object-contain"
                alt="selectIcon"
              />
              <span className="mt-1 text-[#8ABBBD]">
                Click on a variable to add
              </span>
            </div>
          ) : (
            <div className="mt-4 grid grid-cols-6 gap-[10px] px-2">
              {selectedVariables.map((variable) => (
                <button
                  key={variable.text}
                  className={`relative h-14 rounded-[10px] border-2 p-1 xl:text-[16px] xl-max:text-[1vw] ${variable.textColor} ${variable.color} ${variable.borderColor} hover:${variable.color} `}
                  onClick={() => handleRemoveClick(variable)}
                >
                  {variable.text}
                  <div className="absolute -right-[10px] -top-[10px] flex h-6 w-6 items-center justify-center rounded-full bg-[#F9477B]">
                    <XMarkIcon className="h-5 w-5 stroke-2 text-white" />
                  </div>
                </button>
              ))}
            </div>
          )}
        </div>
        <div className="mt-3 flex w-full items-center justify-center gap-2">
          <button
            className={`button-class h-9 rounded-lg px-5 text-lg font-medium text-white shadow-md hover:bg-[#3072D6] disabled:opacity-20`}
            onClick={getEquation}
            disabled={Object.keys(selectedVariables).length === 0}
          >
            Generate Function
          </button>
          <Tooltip
            id="tooltip"
            place="right"
            content="AI Generator"
            border="true"
            style={{
              backgroundColor: "white",
              fontWeight: "bold",
              fontSize: "16px",
            }}
            className="custom-tooltip"
          />
          <button
            className="icon-container button p-2 hover:scale-105"
            data-tooltip-id="tooltip"
            onClick={() => {
              //AutoEquation();
              setIsAIModalOpen(true);
              setIsAIGenerated(true);
              setBeerSalesEquation("");
              setAccuracy(0);
              setChartData(null);
              selectedVariables.splice(0, selectedVariables.length);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="white"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="white"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
              />
            </svg>
          </button>
        </div>
        <h3 className="mt-3 rounded-md border-[0.8px] border-[#ADADAD] bg-[#725297] text-center text-base font-semibold uppercase text-white shadow-lg shadow-[#ADADAD]">
          Generated Function
        </h3>
        {loading ? (
          <div className="mt-0 flex h-28 w-full flex-col items-center justify-center rounded-lg border border-gray-400 bg-[#F2E9FF] px-[40px] py-[10px] shadow-lg shadow-[#ADADAD] ">
            <div className="mr-4 h-10 w-10 animate-spin rounded-full border-[0.8px] border-t-2 border-[#ADADAD] text-center"></div>
          </div>
        ) : (
          <div className="mt-0 flex w-full flex-col rounded-lg border border-gray-400 bg-[#F2E9FF] px-[70px] py-[10px] ">
            <div className="mt-2 flex w-full flex-row gap-x-[10px]">
              <div className="flex w-full items-center justify-between rounded-[10px] border-2 border-[#56E0A3] bg-[#EAFDF4] bg-opacity-[100%] px-[30px] py-[15px] text-lg font-semibold text-[#3FCB8C]">
                Estimated Beer Sales = {beerSalesEquation}
              </div>
              <div className="flex flex-1 justify-between flex-col gap-2">
                <div
                  className="h-full flex w-fit flex-col items-center justify-center whitespace-nowrap rounded-[10px] border-2 border-[#EB5894] bg-[#FDEBF2] bg-opacity-[100%] px-[30px] py-[15px] font-semibold text-[#EB5894] hover:cursor-pointer"
                >
                  <div className="text-xl font-bold">Accuracy</div>
                  <div className="text-3xl"> {accuracy.toFixed(2)} </div>
                  {chartData && (
                    <Link color={'blue.500'} onClick={chartData ? onOpen : () => {}}>Show Function</Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {isModalOpen ? (
          <>
            <div
              className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"
              // onClick={() => {
              //   setIsModalOpen(false);
              // }}
            >
              <div
                className="flex w-[600px] flex-col items-center gap-6 rounded-[20px] border-2 border-[#9DC0EE] bg-[#F5F9FF] px-10 pb-6 pt-8"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="flex flex-row gap-2">
                  <BiSolidInfoSquare className="h-12 w-28 text-[#066AB2]" />
                  <span className="text-lg font-medium">
                    You have tried {generateCounter} times so far, would you
                    like AI to generate the function for you?
                  </span>
                </div>
                <div className="flex flex-row items-center justify-center gap-3">
                  <button
                    id="NO"
                    className="rounded-md border border-[#979FA9] bg-white px-3 py-[3px] text-lg shadow-md hover:bg-[#b0b4d0ac] hover:bg-opacity-10"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsModalOpen(false);
                    }}
                  >
                    No
                  </button>
                  <button
                    id="YES"
                    className={`button-class h-9 rounded-md px-5 text-lg font-medium text-white hover:bg-[#3072D6]`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsModalOpen(false);
                      setIsAIModalOpen(true);
                      setIsAIGenerated(true);
                      setBeerSalesEquation("");
                      setAccuracy(0);
                      selectedVariables.splice(0, selectedVariables.length);
                      //AutoEquation();
                    }}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
          </>
        ) : null}

        {isAIModalOpen ? (
          <>
            <div
              className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"
              onClick={() => {
                setIsAIModalOpen(false);
              }}
            >
              <div
                className="bg-color flex w-[70%] flex-col items-center gap-2 rounded-[20px] border-4 border-[#FFDA21] px-5 pb-4 pt-6"
                onClick={(e) => {
                  // AutoEquation
                  e.stopPropagation();
                }}
              >
                <div className="flex flex-row items-start justify-start gap-2 text-left">
                  <div className="ai-container p-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="white"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="white"
                      className="h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                      />
                    </svg>
                  </div>
                  <span className="text-2xl font-semibold text-[#FFDA21]">
                    AI generated the most accurate equation possible from the
                    data!
                  </span>
                </div>
                <div className="mt-4 flex w-[80%] flex-row gap-x-[10px]">
                  <div className="flex w-[80%] rounded-[10px] border border-[#56E0A3] bg-[#EAFDF4] bg-opacity-[100%] px-[30px] py-[15px] text-lg font-semibold text-[#3FCB8C]">
                    {/* P(Beer Sales) = {AutobeerSalesEquation} */}
                    Estimated Beer Sales = {solution}
                  </div>
                  <div className="flex w-fit flex-col items-center justify-center whitespace-nowrap rounded-[10px] border border-[#EB5894] bg-[#FDEBF2] bg-opacity-[100%] px-[30px] py-[15px] font-semibold text-[#EB5894]">
                    <div className="text-lg">Accuracy</div>
                    <div className="text-3xl"> 0.98 </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center gap-10">
                  <button
                    className={`button-class rounded-md px-5 py-[3px] text-base font-semibold text-white underline-offset-2 hover:bg-[#3072D6]`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsAIModalOpen(false);
                      // setBeerSalesEquation(AutobeerSalesEquation);
                      // setAccuracy(accuracy);
                      // setBeerSalesEquation(solution);
                      // setAccuracy(0.95);
                      setIsGameCompletedModalOpen(true);
                      setIsGame1BCompleted(true);
                      setIsAIGenerated(false);
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
          </>
        ) : null}

        {isGameCompletedModalOpen ? (
          <>
            <div
              className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"
              onClick={() => {
                setIsGameCompletedModalOpen(false);
              }}
            >
              <div
                className="flex flex-col items-center gap-6 rounded-[20px] border-2 border-[#F0B424] bg-[#FFF8EC] px-10 pb-6 pt-8"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className="flex flex-row items-start justify-start gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="#ECB83D"
                    className="h-16 w-20"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                    />
                  </svg>

                  {/* <BiTrophy className="h-16 w-20 text-[#FFDA21]" /> */}
                  <div className="flex flex-col gap-2">
                    <span className="text-xl font-semibold  tracking-wide text-[#2F3F53]">
                      Congratulations!
                    </span>
                    <span className="text-lg  font-medium tracking-wider text-[#2F3F53]">
                      You have completed Game 1B - Predicting Beer Sales
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center gap-3">
                  <button
                    className="rounded-md border border-[#979FA9] bg-white px-5 py-[3px] text-lg font-semibold shadow-md hover:bg-[#edeffeac] hover:bg-opacity-10"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsGameCompletedModalOpen(false);
                    }}
                  >
                    BACK
                  </button>
                  <button
                    className="rounded-md bg-[#F0B424] px-5 py-[3px] text-lg font-semibold text-white shadow-md hover:bg-[#ead787] "
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(isPlayable ? "/play/game2" : "/game2");
                    }}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
          </>
        ) : null}
        <Modal isOpen={isOpen} onClose={onModalClose} size={"xl"} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ScatterPlot
                predictions={chartData?.predictions}
                highestPredictions={chartData?.highestPrediction}
                observedValues={chartData?.observedValues}
                rSquared={accuracy}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
}
