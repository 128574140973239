import axiosClient from "config/apiClient";

export const fetchData = async (url) => {
    try {
      const response = await axiosClient.get(url);
      return response.data;
    } catch (error) {
      console.error(`Error fetching data from ${url}:`, error);
      return null;
    }
  };