import { useCommonToast } from "components/toast/toast";
import { client } from "../service/client";
import {
  getAllClientsucessrespone,
  getAllClientfailResponse,
  createClientsucessrespone,
  createClientfailResponse,
  clientPayload,
  putTenantsuccessResponse,
  putTenantfailResponse,
  getallTenantsuccessResponse,
  getAllTenantfailResponse
} from "./types/client.model";

export const getAllclient = async (): Promise<
  getAllClientsucessrespone | getAllClientfailResponse
> => {
  try {
    const response = await client.getclient();
    const data: getAllClientsucessrespone = response.data;
    if (response.status === 200) {
      return data;
    }
  } catch (getAllClientfailResponse) {
    console.error(" getAllpatron failed", getAllClientfailResponse);
  }
};


export const createclient = async (clientPayload: clientPayload): Promise<createClientsucessrespone|createClientfailResponse> => {
  try {
    const response = await client.createClient(clientPayload);

    const data: createClientsucessrespone = response.data;
   if (response.status === 201) {
      return data;
      }  
  } catch (createClientfailResponse:any) {
    throw createClientfailResponse;
  }
};



export const getByIdClient = async (queryParams): Promise<
  getAllClientsucessrespone | getAllClientfailResponse
> => {
  try {
    const response = await client.getByidclient(queryParams);
    const data = response.data; 
    if (response.status === 200) {
      return data;
    }
  } catch (getAllClientfailResponse) {
    throw getAllClientfailResponse
  }
};





export const putbyidClient = async (queryParams,putClientpayload): Promise<putTenantsuccessResponse|putTenantfailResponse> => {
  try {
    const response = await client.putbyidClient(queryParams,putClientpayload);

    const data: putTenantsuccessResponse = response.data;

   if (response.status === 200) {
      return data;
      }  
  } catch (putConfigurationFailresponse) {
    throw putConfigurationFailresponse

  }
};




export const getAllTenant = async (): Promise<
  getallTenantsuccessResponse[] | getAllTenantfailResponse
> => {
  try {
    const response = await client.getclient();

    const data: getallTenantsuccessResponse[] = response.data;
    if (response.status === 200) {
      return data;
    }
  } catch (getAllTenantfailResponse) {
    throw getAllTenantfailResponse
  }
};
